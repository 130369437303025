/* Header
-----------------------------------------------------------------------------*/

.header {
	position: absolute;
	top: 0;
	left: 0;
    width: 100%;
    padding: 20px 40px 0;
    color: #ffffff;
    z-index: 9999;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        .logo {
            display: none;
        }

        .menu-btn {
            .top {
                transform: rotate(-45deg);
            }
            .middle {
                margin: -4px 0 0 0;
                transform: rotate(45deg);
            }
            .bottom {
                margin: 0;
                opacity: 0;
            }
        }
    }

    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1800px;
        min-height: 90px;
        margin: 0 auto;
    }

    .left-block {
        display: flex;
        align-items: center;
    }
    .right-block {
        display: flex;
        align-items: center;
    }

    .btn {
        padding: 13px 20px;
        border-width: 5px;
        font-size: 14px;
        border-radius: 5px;

        &:active {
            margin-bottom: 0;
        }
    }

    .contact-wrap {
        display: flex;
        align-items: flex-end;

        .phone {
            margin-left: 170px;
            font-family: 'Proxima Nova Extrabld';
            font-size: 34px;
            color: #ffffff;
            text-decoration: none;
        }

        .free {
            margin-left: 70px;
            padding: 0 0 8px;
            font-size: 20px;
        }
    }

    .address-wrap {
        margin-left: 60px;
        padding-left: 30px;
        color: #ffffff;

        .city {
            position: relative;
            margin: 0 0 10px;
            font-family: 'Proxima Nova Extrabld';
            font-size: 14px;
            text-transform: uppercase;

            &:before {
                content: '/';
                position: absolute;
                top: 0;
                left: -30px;
                font-family: 'Proxima Nova Black';
                font-size: 16px;
                color: #ee0e0e;
            }
        }
    }
}

.menu {
    margin: 0 0 0 116px;

    .item {
        margin: 0 0 0 80px;
        font-family: 'Proxima Nova Extrabld';
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;

        &:first-child {
            margin: 0;
        }
    }
}

.menu-btn {
    display: none;
    position: relative;

    span {
        display: block;
        width: 40px;
        height: 4px;
        margin-top: 10px;
        background: #ffffff;
        transition: 0.3s;

        &:first-child {
            margin: 0;
        }
    }
}

.top-menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #000000e6;
    color: #ffffff;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    z-index: 999;

    &.show {
        opacity: 1;
        pointer-events: all;
    }

    .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 180px);
        margin-top: 100px;
        padding: 0 70px;
        overflow: auto;
    }

    .logo {
        max-width: 390px;
        margin-bottom: 160px;
    }

    .phone {
        color: #ffffff;
        font-family: 'Proxima Nova Bold';
        font-size: 46px;
        text-align: left;
        text-decoration: none;
    }

    .free {
        margin-top: 20px;
        font-size: 24px;
    }

    .email-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;

        a {
            font-family: 'Proxima Nova Bold';
            font-size: 38px;
        }

        span {
            display: block;
            margin-top: 20px;
            font-size: 24px;
            font-family: 'Proxima Nova Bold';
        }
    }

    .button-wrap {
        width: 100%;
        max-width: 504px;
        margin: 140px auto 0;
    }

    .btn {
        max-width: none;
        margin: 35px auto 0;
        padding: 34px 20px;
        font-size: 25px;

        &.btn-empty {
            border-radius: 10px;
        }
    }
}


/*** Media ***/

@media (max-width: 1661px) {
    .header {
        .contact-wrap {
            .phone {
                margin-left: 0;
            }
            .free {
                margin-left: 40px;
            }
        }



        .menu {
            margin: 0 0 0 30px;

            .item {
                margin: 0 0 0 40px;
            }
        }

        .phone-wrap {
            margin-left: 30px;
        }

        .address-wrap {
            margin-left: 30px;
            padding-left: 20px;

            .city {
                &:before {
                    left: -20px;
                }
            }
        }
    }
}

@media (max-width: 1441px) {
    .header {
        .contact-wrap {
            .phone {
                font-size: 30px;
            }
            .free {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 1301px) {
    .header {
        align-items: flex-start;

        .right-block {
            flex-direction: column-reverse;
            align-items: flex-end;

            .contact-wrap {
                padding-bottom: 20px;
            }

            .phone-wrap {
                margin-left: 0;
            }
        }
    }
}

@media (max-width: 1281px) {
    .header {
        .logo {
            max-width: 400px;
        }

        .phone-wrap {
            margin-left: 20px;
        }

        .contact-wrap {
            .phone {
                font-size: 26px;
            }

            .free {
                margin-left: 20px;
                padding-bottom: 5px;
            }
        }

        .address-wrap {
            margin-left: 20px;
        }

        .menu {
            .item {
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: 1025px) {
    .header {
        .menu,
        .contact-wrap,
        .btn {
            display: none;
        }
    }

    .menu-btn {
        display: block;
    }
}

@media (max-width: 561px) {
    .header {
        padding-right: 30px;
        padding-left: 30px;

        .logo {
            font-size: 30px;

            span {
                font-size: 15px;
            }
            .red {
                margin-left: 10px;
            }
        }
    }

    .top-menu-mobile {
        .wrap {
            padding: 0 20px;
        }

        .logo {
            margin-bottom: 60px;
        }

        .phone {
            font-size: 28px;
        }
        .free {
            margin-top: 10px;
            font-size: 20px;
        }

        .email-wrap {
            margin-top: 40px;

            a {
                font-size: 28px;
            }

            span {
                margin-top: 10px;
                font-size: 20px;
            }
        }

        .button-wrap {
            margin-top: 30px;

            .btn {
                padding: 20px 10px;
                font-size: 20px;
            }
        }
    }
}