* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Geometria';
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

p {
    margin-top: 70px;
    font-size: 32px;
    line-height: 47px;

    &:first-child {
        margin-top: 0;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.noscroll {
    overflow: hidden;
}


html {
    height: 100%;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}
body {
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    height: 100%;
}
.wrapper {
    margin: 0 auto;
    min-height: 100%;
    overflow: hidden;
}

.container-block {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 70px;
}

img {
    max-width: 100%;
}

h1, .h1 {
    font-size: 74px;
    font-family: 'Proxima Nova Bold';
}
h2, .h2 {
    font-size: 70px;
    font-family: 'Proxima Nova Bold';
}
h3, .h3 {
    font-size: 58px;
    line-height: 67px;
    font-family: 'Proxima Nova Bold';
}
h4, .h4 {
    font-size: 45px;
    line-height: 50px;
    font-family: 'Proxima Nova Bold';
}

.text-center {
    text-align: center;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 33px 60px;
    background: #518bf7;
    font-family: 'Proxima Nova Bold';
    font-size: 21px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s;
    border: 0;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }

    span {
        color: #ffffff;
        text-transform: uppercase;
    }

    &.btn-empty {
        background: none;
        border: 1px solid;
        color: #000;
    }

    &.btn-white {
        background: #fff;
        color: #000;

        &.btn-empty {
            background: none;
            border: 1px solid #fff;
            color: #fff;
        }
    }

    &.btn-yellow {
        background: #f7da3a;
        color: #000;

        &.btn-empty {
            background: none;
            border: 1px solid #f7da3a;
            color: #000;
        }
    }

    &.btn-small {
        padding: 18px 40px;
        font-size: 20px;
    }
}

/***/

ul {
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    li {
        margin-top: 10px;
        font-size: 18px;

        &:first-child {
            margin-top: 0;
        }
    }
}


/*** Spoiler ***/

.spoiler-block {
    &-item {
        margin-top: 35px;
        border: 1px solid #bababa;
        border-radius: 10px;

        &:first-child {
            margin: 0;
        }

        &.active {
            .head {
                .icon {
                    justify-content: flex-start;
                    transform: rotate(180deg);
                }
            }
            .body {
                display: block;
            }
        }
    }
    .head {
        display: flex;
        align-items: center;
        padding: 52px 80px;
        font-weight: bold;
        font-size: 18px;
        line-height: normal;
        overflow: hidden;
        cursor: pointer;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .num {
            width: 100px;
            font-size: 72px;
            line-height: 60px;
            font-weight: 400;
        }

        .title {
            flex: 1;
            font-family: 'Proxima Nova Semibold';
            font-size: 25px;
            line-height: 37px;
            text-transform: uppercase;
        }

        .icon {
            display: flex;
            justify-content: flex-end;
            width: 100px;
        }
    }

    .body {
        display: none;
        margin-top: -5px;
        padding: 0 200px 70px;

        p {
            font-family: 'Geometria';
            font-size: 24px;
            line-height: 35px;
        }
    }
}