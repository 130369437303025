/* Media
-----------------------------------------------------------------------------*/

@import '_var.scss';

@media (max-width: 1661px) {
    h1, .h1 {
        font-size: 66px;
        line-height: 66px;
    }
    h2, .h2 {
        font-size: 50px;
        line-height: 58px;
    }
    h3, .h3 {
        font-size: 48px;
        line-height: 57px;
    }
    h4, .h4 {
        font-size: 45px;
        line-height: 50px;
        font-family: 'Proxima Nova Bold';
    }

    p {
        margin-top: 40px;
        font-size: 27px;
        line-height: 38px;
    }

    .request-block p {
        font-size: 30px;
    }

    .statistics {
        margin-top: 60px;

        .item {
            padding: 35px;
        }

        .num {
            font-size: 70px;
        }
        .label {
            font-size: 20px;
            line-height: 25px;
        }
    }

    .input-wrap {
        .icon {
            left: 25px;
        }
        .input {
            padding-left: 60px;
        }
    }

    .first-block {
        padding-top: 250px;

        .title {
            p {
                margin-top: 40px;
                font-size: 36px;
            }
        }

        .form {
            margin-top: 40px;
        }
    }

    .map-wrap {
        padding-top: 80px;

        .map {
            margin-top: 60px;
        }
    }

    /***/

    .advantages-list {
        %-big {
            margin-top: 70px;

            .item {
                p {
                    font-size: 30px;
                    line-height: 45px;
                }
            }

            .title {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }

    /***/

    .request-block {
        .btn {
            display: inline-block;
            max-width: none;
            height: auto;
        }
    }

    /***/

    .total-cost {
        padding: 100px 0;

        .text {
            margin-top: 40px;
            font-size: 30px;
            line-height: 46px;
        }

        .cost {
            font-size: 180px;
        }
    }

    .advantages-list-big {
        margin-top: 0;

        .item {
            padding-top: 80px;

            &.press-right {
                .text {
                    margin-right: 0;
                }

                .button-wrap {
                    margin-top: 100px;
                }
            }

            &.press-right {
                .text {
                    .button-wrap {
                        margin-top: 100px;
                    }
                }
            }

            &.empty-image {
                margin-top: 40px;
            }
        }

        .button-wrap {
            margin-top: 100px;
        }
    }

    /***/

    .advantages-block {
        margin-top: 40px;
    }

    .programs-block {
        margin-left: -50px;

        &-item {
            margin-left: 50px;

            &:hover {
                .text {
                    padding: 40px 25px 25px;
                }
            }

            .text {
                padding: 40px;
            }
        }
    }

    .services-block {
        .wrap {
            margin: -50px 0 0 -50px;
        }
        &-item {
            padding: 50px 0 0 50px;
        }
    }

    .form-block-wrap {
        .left-image {
            top: 80px;

            img {
                max-height: 200px;
            }
        }
    }


    .table-wrap {
        padding: 80px;
    }


    .map-wrap {
        .info {
            left: 40px;
        }
    }
}

@media (max-width: 1441px) {
    .input {
        height: 64px;
    }

    .btn {
        padding: 20px 40px;
    }


    .first-block {
        .title {
            p {
                font-size: 32px;
            }
        }
    }


    .total-cost {
        .cost {
            font-size: 90px;
        }

        .text {
            margin-top: 30px;
            font-size: 26px;
            line-height: 36px;
        }
    }

    .advantages-wrap {
        padding-top: 30px;

        &:before {
            display: none;
        }

        .advantages-title {
            font-size: 47px;
            line-height: 52px;
        }
    }

    .advantages-list-big {
        margin-top: 0;

        .item {
            &.press-right {
                .text {
                    .button-wrap {
                        margin-top: 60px;
                    }
                }
            }

            .title {
                font-size: 45px;
                line-height: 50px;
            }

            p {
                font-size: 26px;
                line-height: 35px;
            }
        }
        .button-wrap {
            margin-top: 60px;
        }
    }

    .form-block-wrap {
        .right-top-image {
            right: 80px;
        }
    }

    .subscribe-block-wrap {
        .social-block-wrap {
            margin-top: 100px;
        }
    }

    .map-wrap {
        iframe {
            height: 700px;
        }

        .info {
            .city {
                margin-bottom: 10px;
                font-size: 40px;

                &:after {
                    font-size: 45px;
                }
            }

            .address-wrap {
                margin-bottom: 10px;

                span {
                    font-size: 37px;

                    span {
                        font-size: 18px;
                    }
                }
            }
            .phone {
                font-size: 55px;
            }

            .btn {
                margin-top: 30px;
            }

            .mail {
                margin-top: 20px;
                font-size: 20px;

                .icon {
                    margin-right: 20px;
                    font-size: 20px;
                }
            }
        }
    }

    .packages-wrap {
        .packages-title {
            font-size: 50px;
            line-height: 58px;
        }
    }

    .packages-list {
        .head {
            .num {
                padding-right: 100px;
                font-size: 200px;
            }

            .title-wrap {
                .title {
                    font-size: 53px;
                    line-height: 45px;
                }
            }

            .list-benefits {
                .item {
                    padding-right: 40px;
                }
            }
        }
    }

    /***/

    .franchisees-list {
        margin-top: 80px;

        .image {
            max-width: 300px;
            padding-right: 60px;
        }

        .franchisees-statistics {
            padding-left: 60px;
        }
    }

    /***/

    .modal {
        .cross {
            top: 20px;
            right: 20px;
        }
    }
}

@media (max-width: 1281px) {
    h1, .h1 {
        font-size: 60px;
        line-height: 60px;
    }

    .first-block {
        margin-bottom: 80px;
        padding-top: 200px;
        padding-bottom: 120px;

        .left-block {
            max-width: none;
        }

        .form {
            .input {
                height: 52px;
                font-size: 14px;
            }
        }
    }

    .spoiler-block {
        .head {
            padding: 50px;

            .num {
                padding-right: 20px;
                font-size: 60px;
            }

            .title {
                padding-right: 20px;
                font-size: 20px;
                line-height: 30px;
            }
        }

        .body {
            padding: 0 150px 50px;

            p {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    p {
        font-size: 23px;
        line-height: 34px;
    }

    .btn {
        padding: 14px 20px;
        font-size: 16px;
    }

    .statistics {
        margin: 100px 0 0 -20px;

        .item {
            margin: 0 0 0 20px;
            padding: 25px;
            border-radius: 10px;
        }

        .num {
            margin-right: 20px;
            font-size: 60px;
        }

        .label {
            font-size: 16px;
            line-height: 23px;
        }
    }

    .services-block {
        .title {
            margin-bottom: 30px;
            font-size: 26px;
            line-height: 32px;
        }

        p {
            font-size: 18px;
        }
    }

    .advantages-list-big {
        .item {
            padding-top: 60px;

            p {
                font-size: 21px;
                line-height: 30px;
            }
        }
    }

    .packages-list {
        margin-top: -100px;

        .head {
            .num {
                padding-right: 70px;
                font-size: 150px;
            }

            .title-wrap {
                .label {
                    font-size: 20px;
                    letter-spacing: 7px;
                }

                .title {
                    margin-left: 30px;
                    font-size: 43px;
                    line-height: 37px;
                }
                
                .button-wrap {
                    margin-left: 30px;
                }
                .profitably {
                    font-size: 16px;
                    line-height: normal;
                }
                .condition-btn {
                    font-size: 16px;
                    line-height: normal;
                }
            }

            .list-benefits {
                .item {
                    padding-right: 20px;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }

        .body {
            margin-top: 60px;

            .alert-text {
                margin-top: 20px;

                .gray-text {
                    font-size: 18px;
                }
                .yellow-block {
                    font-size: 18px;
                }
            }

            .cost-wrap {
                margin-bottom: 30px;

                .cost {
                    font-size: 45px;
                }

                .vat {
                    font-size: 14px;
                }
            }
        }

        .profitably {
            padding: 5px 20px;
        }
    }

    .franchisees-list {
        .image {
            .name {
                margin-top: 20px;
                font-size: 27px;
                line-height: 30px;

                span {
                    display: inline;
                }
            }
            .town {
                margin-top: 20px;
                font-size: 20px;
            }
        }

        .text {
            p {
                font-size: 23px;
                line-height: 34px;
            }
        }

        .franchisees-statistics {
            .num {
                font-size: 78px;
                line-height: 60px;
            }
            .label {
                font-size: 20px;
                line-height: 23px;
            }
        }
    }

    .faq-wrap {
        padding-top: 60px;

        .spoiler-block {
            margin-top: 60px;
        }
    }

    .form-block-wrap {
        padding-bottom: 40px;

        .left-image {
            img {
                max-height: 160px;
            }
        }
        .right-top-image {
            img {
                max-width: 160px;
            }
        }
        .right-image {
            display: none;
        }
    }

    .form-block {
        padding: 70px 20px;

        .form {
            max-width: none;

            .row {
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
            }

            .col {
                width: 100%;
                flex: 100%;
                max-width: 500px;
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }
            }

            .input {
                height: 70px;
                font-size: 14px;
            }

            .btn {
                padding: 20px;
            }
        }
    }
}

@media (max-width: 1025px) {
    h1, .h1 {
        font-size: 56px;
        line-height: 60px;
    }
    h2, .h2 {
        font-size: 40px;
        line-height: 45px;
    }
    h3, .h3 {
        font-size: 35px;
        line-height: 45px;
    }
    h4, .h4 {
        font-size: 35px;
        line-height: 40px;
    }

    p {
        font-size: 28px;
        line-height: 38px;
    }

    .btn {
        &.btn-small {
            padding: 5px 20px;
            font-size: 14px;
        }
    }

    .first-block {
        .title {
            p {
                margin-top: 30px;
                font-size: 41px;
                line-height: 41px;
            }
        }
    }

    .map-wrap {
        .map {
            margin-top: 45px;
        }
    }

    .statistics {
        flex-wrap: wrap;
        margin-top: 60px;

        .item {
            flex: calc(50% - 20px);
            max-width: calc(50% - 20px);
            margin-top: 20px;
        }
    }

    .advantages-wrap {
        padding-bottom: 150px;
    }

    .advantages-block {
        &-item {
            flex: 50%;
            max-width: 50%;
        }
    }

    .advantages-list {
        .image {
            padding: 0;
        }

        .text {
            padding: 20px;
        }
    }

    .advantages-list-big {
        .item {
            &.press-right {
                .image {
                    margin: 0 -71px 0 -75px;
                }

                .text {
                    .button-wrap {
                        max-width: none;
                        flex-direction: row;
                        justify-content: center;
                        margin-left: -15px;

                        .label {
                            margin-left: 15px;
                        }

                        .btn {
                            flex: calc(50% - 15px);
                            max-width: 260px;
                            margin-left: 15px;
                            padding: 15px 20px;
                            font-family: 'Proxima Nova';
                            font-size: 20px;
                        }
                    }
                }

                .image {
                    display: none;
                }

                .mobile-block,
                .mobile-block .image {
                    display: block;
                }

                .button-wrap {
                    flex-wrap: wrap;

                    .label {
                        flex: 100%;
                        text-align: center;
                    }
                }
            }

            .title {
                font-size: 42px;
                line-height: 44px;
            }

            p {
                margin-top: 40px;
            }
        }

        .button-wrap {
            max-width: 190px;

            .label {
                margin-bottom: 10px;
                font-size: 16px;
            }
        }
    }

    .request-block {
        padding: 100px 0;

        .right-col {
            display: flex;
            justify-content: flex-end;
        }

        p {
            font-size: 26px;
            line-height: 32px;
        }
    }

    .services-block {
        margin: 80px 0 0 0;
    }

    .services-block {
        .wrap {
            margin-left: -30px;
        }

        &-item {
            padding: 50px 0 0 30px;
        }
    }

    .total-cost {
        padding: 60px 0;
    }

    .steps {
        margin-top: 60px;

        .wrap {
            margin: -40px 0 0 -40px;
        }

        .item {
            padding: 40px 0 0 40px;
        }
    }

    .packages-wrap {
        padding: 40px 0 0;

        .packages-title {
            font-size: 40px;
            line-height: 45px;
        }
    }

    .packages-list {
        .head {
            .list-benefits {
                flex-wrap: wrap;
                margin-top: 50px;

                .item {
                    flex: calc(50% - 20px);
                    margin: 20px 20px 0 0;
                    padding: 0 0 0 20px;
                }
            }
        }

        .body {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 30px;

            .right-block {
                width: 100%;
                padding-top: 40px;
            }

            p {
                font-size: 18px;
                line-height: 24px;
            }

            .alert-text {
                margin-top: 15px;

                .yellow-block {
                    padding: 2px 5px;
                    font-size: 12px;
                }
            }
        }
    }
}

@media (max-width: 769px) {
    .btn {
        padding: 36px 30px;
        font-size: 24px;
    }

    .first-block {
        background-position: right;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.50;
            z-index: 1;
        }

        .wrap {
            position: relative;
            z-index: 5;
        }

        .form {
            display: none;
        }
        .mobile-block {
            display: block;
        }
    }

    /***/

    .request-block {
        flex-direction: column;
        align-items: flex-start;
        padding: 60px 0;

        .right-col {
            padding: 30px 0 0;
        }

        p {
            margin-top: 30px;
        }
    }

    /***/

    .spoiler-block {
        .head {
            padding: 30px;

            .num {
                display: none;
            }

            .title {
                font-size: 15px;
                line-height: 20px;
                font-family: 'Proxima Nova Bold';
            }

            .icon {
                width: 30px;
            }
        }

        .body {
            padding: 0 30px 50px;

            p {
                font-size: 16px;
                line-height: 25px;
            }
        }
    }

    .first-block {
        padding-top: 160px;

        .form {
            .row {
                flex-wrap: wrap;

                .col {
                    flex: 100%;
                    margin-top: 20px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .statistics {
        .item {
            flex: calc(100% - 20px);
            max-width: calc(100% - 20px);
            justify-content: center;
        }

        .num {
            width: 35%;
            margin-right: 26px;
            font-size: 80px;
            text-align: right;
        }

        .label {
            max-width: 200px;
            font-family: 'Proxima Nova Bold';
            font-size: 23px;
            line-height: 28px;
        }
    }

    .advantages-wrap {
        padding-bottom: 50px;

        &:after {
            display: none;
        }

        .advantages-title {
            margin-bottom: 40px;
        }
    }

    .advantages-block {
        margin-left: -40px;

        &-item {
            padding: 40px 0 0 40px;
        }

        .title {
            font-size: 31px;
        }

        p {
            font-size: 13px;
        }
    }

    .advantages-list {
        .item {
            flex-direction: column;
            margin-top: 30px;

            &:nth-child(2n) {
                flex-direction: column-reverse;
            }

            .image {
                position: relative;
                margin: 0 -20px;
                z-index: 1;
            }

            .text {
                position: relative;
                padding: 0 0 10px;
                z-index: 5;
            }
        }

        .title {
            font-size: 25px;
            line-height: 30px;
        }

        p {
            margin-top: 20px;
            font-size: 18px;
            line-height: 25px;
        }
    }

    .advantages-list + .advantages-list-big {
        margin-top: 30px;
    }

    .advantages-list-big {
        .item {
            display: block;
            padding-top: 40px;

            &:first-child {
                padding-top: 0;
            }

            &.press-left {
                padding: 0;

                .image {
                    max-width: 100%;
                }
                .text {
                    max-width: none;
                    margin: -20px 0 0;
                    padding: 20px 70px;
                }
            }

            &.press-right {
                display: flex;
                flex-direction: column;
                padding: 0;

                .text {
                    max-width: none;
                    margin-top: 20px;
                    padding: 20px 70px;

                    .button-wrap {
                        margin-top: 50px;
                    }
                }
            }

            &.empty-image {
                margin-top: 0;
                padding: 50px 0 0;
            }

            p {
                font-size: 30px;
                line-height: 40px;
            }
        }

        .button-wrap {
            max-width: none;
            margin-top: 80px;

            .label {
                font-family: 'Proxima Nova Semibold';
                font-size: 24px;
            }

            .btn {
                width: 100%;
                max-width: 500px;
                padding: 36px 30px;
                font-size: 24px;
            }
        }
    }

    .form-block {
        .label {
            max-width: 270px;
            margin: 0 auto 50px;
            text-align: center;
        }
    }

    .services-block-wrap {
        .services-title {
            text-align: left;
        }
    }

    .services-block {
        &-item {
            flex: 100%;
            max-width: 100%;
        }

        .icon {
            display: none;
        }

        .title {
            padding-left: 32px;
            font-size: 23px;
            line-height: 26px;
            border-left: 5px solid #518bf7;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .subscribe-block-wrap {
        padding-top: 40px;
    }

    /***/

    .steps {
        .item {
            flex: 50%;
            max-width: 50%;
        }


        .image {
            margin-bottom: 30px;
        }

        .title {
            margin-bottom: 20px;
            font-size: 18px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }


    /***/

    .packages-wrap {
        padding: 20px 0 0;
    }

    .packages-list {
        margin: 20px 0 0;

        &-item {
            margin-top: 30px;
            padding: 60px 30px;
            border: 1px solid #bababa;
            border-radius: 10px;

            &:first-child {
                border: 1px solid #bababa;
            }
        }

        .head {
            flex-wrap: wrap;

            .left-block {
                display: flex;
                flex: 100%;

                .title-wrap {
                    display: block;
                    padding-left: 50px;
                }
            }

            .right-block {
                .title-wrap {
                    display: none;
                }
            }

            .num {
                padding: 0;
                font-size: 115px;
                line-height: 84px;
            }

            .title {
                margin-left: 20px;
                font-size: 35px;
            }

            .title-wrap {
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-start;

                .label {
                    font-size: 18px;
                }

                .title {
                    margin: 10px 0 0;
                    font-size: 34px;
                }

                .button-wrap {
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: flex-start;
                    margin: 20px 0 0;
                }

                .condition-btn {
                    margin: 10px 0 0;
                }
            }
        }

        .body {
            margin-top: 40px;

            .left-block {
                flex: 1;
                padding-right: 20px;
            }

            .cost-wrap {
                justify-content: flex-start;

                .cost {
                    font-size: 38px;
                }
            }

            .alert-text {
                .yellow-block {
                    font-size: 14px;
                }
            }

            .button-wrap {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-left: -20px;

                .condition-btn {
                    display: block;
                }
            }

            .btn {
                margin-left: 20px;
                padding: 13px 30px;
                font-size: 15px;
            }
            .condition-btn {
                margin-left: 20px;
                padding: 13px 30px;
            }
        }

        .profitably {
            &.mobile {
                display: block;
                position: absolute;
                top: -12px;
                right: 25px;
                font-size: 14px;
            }
        }
    }

    /***/

    .franchisees-wrap {
        padding-bottom: 40px;

        &:after {
            display: none;
        }
    }

    .franchisees-list {
        &-item {
            flex-wrap: wrap;
            margin-top: 50px;
            padding-top: 0;
            border: 0;

            .image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 100%;
                max-width: none;
                padding: 0;
                order: 1;

                img {
                    max-width: 130px;
                }

                .name-wrap {
                    padding-left: 40px;
                }

                .name {
                    margin: 0;
                }
                .town {
                    margin: 10px 0 0;
                }
            }

            .text {
                flex: 100%;
                padding: 40px 0 0;
                order: 3;

                &.active {
                    .mobile-block {
                        display: block;
                    }

                    .button-wrap {
                        display: none;
                    }
                }

                p {
                    font-size: 20px;
                    line-height: 30px;
                }

                .mobile-block {
                    display: none;
                    margin-top: 20px;
                }

                .button-wrap {
                    display: flex;
                    justify-content: center;
                }
            }

            .franchisees-statistics {
                display: flex;
                flex: 100%;
                max-width: none;
                margin-left: -70px;
                padding: 40px 0 0;
                order: 2;

                .item {
                    max-width: none;
                    margin-top: 0;
                    padding: 0 0 0 70px;
                }
            }
        }
    }

    /***/

    .faq-wrap {
        padding-top: 20px;

        .spoiler-block {
            margin-top: 40px;
        }
    }

    /***/

    .map-wrap {
        .map {
            img {
                display: none;

                &.mobile {
                    display: block;
                }
            }
        }
    }

    /***/

    .form-block-wrap {
        padding: 60px 70px 20px;

        p {
            margin-top: 30px;
        }

        .title {
            max-width: 330px;
            margin: 0 auto;
        }
    }

    .form-block {
        margin: 70px -95px 0;
        padding: 80px 0 450px;
        background-position: right;

        .form {
            .btn {
                padding: 25px 20px;
                font-size: 20px;
            }
        }
    }

    /***/

    .modal {
        padding: 50px;

        &-wrap {
            .title {
                margin-bottom: 30px;
                font-size: 30px;
                line-height: 36px;
            }
        }

        .cross {
            top: 10px;
            right: 10px;
            width: 40px;
            height: 40px;
        }

        .form {
            .row {
                flex-wrap: wrap;
            }

            .col {
                flex: 100%;
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }
            }

            .btn {
                padding: 15px 20px;
                font-size: 17px;
            }
        }

        .phone-wrap {
            margin-top: 20px;

            span {
                margin-bottom: 10px;
                font-size: 14px;
            }

            a {
                font-size: 30px;
            }
        }
    }
}

@media (max-width: 561px) {
    .container-block,
    .content-block {
        padding: 0 20px;
    }

    h1, .h1 {
        font-size: 32px;
        line-height: 40px;
    }
    h2, .h2 {
        font-size: 28px;
        line-height: 34px;
    }
    h3, .h3 {
        font-size: 22px;
        line-height: 26px;
    }

    p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 26px;
    }

    .btn {
        padding: 20px;
        font-size: 16px;
    }

    .spoiler-block {
        &-item {
            margin-top: 20px;
        }

        .head {
            padding: 20px;

            .num {
                width: 50px;
                font-size: 30px;
            }

            .title {
                font-size: 14px;
                line-height: 22px;
            }

            .icon {
                width: 30px;
            }
        }

        .body {
            padding: 0 20px 30px;

            p {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }


    .first-block {
        margin-bottom: 60px;
        padding-top: 130px;
        padding-bottom: 80px;

        .title {
            p {
                font-size: 20px;
                line-height: 25px;
            }
        }

        .mobile-block {
            margin-top: 60px;

            .btn {
                padding: 20px 10px;
                font-size: 20px;
            }
        }
    }

    /***/

    .statistics {
        margin-top: 20px;

        .num {
            margin-right: 20px;
            font-size: 50px;
        }

        .label {
            font-size: 16px;
            line-height: 20px;
        }
    }

    /***/

    .map-wrap {
        padding-top: 60px;
    }

    /***/

    .services-block {
        margin: 60px 0 0 0;

        &-item {
            flex: 100%;
            max-width: 100%;
        }

        .icon {
            margin-bottom: 30px;
        }
    }

    /***/

    .total-cost {
        .cost {
            font-size: 50px;
        }

        .text {
            margin-top: 20px;
            font-size: 16px;
            line-height: 25px;
        }
    }

    /***/

    .advantages-wrap {
        padding: 60px 0 130px;

        .advantages-title {
            font-size: 34px;
            line-height: 40px;
        }
    }

    .advantages-list-big {
        .item {
            .title {
                font-size: 35px;
            }
            p {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .item.press-left {
            .text {
                padding: 20px;
            }
        }
        .item.press-right {
            .image {
                margin: 0 -21px;
            }
            .text {
                padding: 20px;

                .button-wrap {
                    margin: 40px 0 0;

                    .label {
                        margin: 0 0 10px;
                        font-size: 20px;
                    }

                    .btn {
                        padding: 10px;
                        font-size: 16px;
                    }
                }
            }
        }

        .button-wrap {
            margin-top: 40px;

            .btn {
                padding: 20px;
                font-size: 16px;
            }
        }
    }

    .advantages-list {
        margin-top: 60px;

        .item {
            flex-direction: column;
            margin-top: 20px;

            &:nth-child(2n) {
                flex-direction: column-reverse;
            }
        }

        .text {
            margin-top: 0;
            padding: 0 0 20px;
        }

        .title {
            font-size: 18px;
            line-height: 26px;
        }

        p {
            margin-top: 15px;
            font-size: 16px;
            line-height: 22px;
        }
    }


    /***/

    .steps {
        .wrap {
            margin: -20px 0 0 -20px;
        }

        .item {
            flex: 100%;
            max-width: 100%;
            padding: 20px 0 0 20px;
        }

        .image {
            margin-bottom: 20px;
        }
    }

    /***/

    .packages-wrap {
        padding: 0;

        .packages-title {
            font-size: 28px;
            line-height: 35px;
        }
    }

    .packages-list {
        &-item {
            margin-top: 40px;
            padding: 40px 20px;
        }

        .head {
            .left-block {
                flex-direction: column;

                .title-wrap {
                    padding: 10px 0 0;
                }
            }

            .num {
                font-size: 100px;
            }

            .title-wrap {
                .title {
                    font-size: 35px;
                }
            }

            .list-benefits {
                margin-top: 30px;
            }
        }

        .body {
            flex-direction: column;
            margin-top: 30px;

            .left-block {
                padding: 0 0 20px;
            }

            .cost-wrap {
                .cost {
                    font-size: 30px;
                }
            }

            .button-wrap {
                margin: 0 0 0 -10px;

                .condition-btn {
                    flex: calc(50% - 10px);
                    margin-left: 10px;
                    padding: 10px;
                }
                .btn {
                    flex: calc(50% - 10px);
                    margin-left: 10px;
                    padding: 10px;
                }
            }
        }
    }

    /***/

    .franchisees-list {
        &-item {
            margin-top: 50px;
            padding-top: 50px;

            .image {
                flex-direction: column;
                align-items: flex-start;

                .name-wrap {
                    padding-left: 0;
                }

                .name {
                    flex: 100%;
                    padding-left: 0;
                }
                .town {
                    flex: 100%;
                    padding-left: 0;
                }
            }

            .text {
                p {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            img {
                margin-bottom: 20px;
            }

            .franchisees-statistics {
                flex-direction: column;
                margin: 0;
                padding: 20px 0 0;

                .item {
                    padding: 40px 0 0;
                }
            }
        }
    }

    /***/

    .form-block-wrap {
        padding: 60px 20px 20px;
    }

    .form-block {
        margin: 70px -30px 0;
        padding: 40px 30px;

        .container-block {
            padding: 0;
        }

        .form {
            .btn {
                padding: 20px;
                font-size: 16px;
            }
        }
    }

    /***/

    .modal {
        padding: 40px 30px;

        .title {
            &:before {
                display: none;
            }
        }
    }
}

@media (max-width: 375px) {
    .packages-list {
        .body {
            .button-wrap {
                margin: 0;

                .condition-btn {
                    flex: 100%;
                    margin-left: 0;
                    padding: 10px;
                }
                .btn {
                    flex: 100%;
                    margin: 10px 0 0;
                }
            }
        }
    }


    .advantages-list-big {
        .item.press-right {
            .text {
                .button-wrap {
                    .btn {
                        flex: 100%;
                        margin: 10px 0 0;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}