@font-face {
  font-family: 'als_rublregular';
  src: url("../fonts/rouble/rouble-webfont.woff2") format("woff2"), url("../fonts/rouble/rouble-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icons/icomoon.eot?d7r1sb");
  src: url("../fonts/icons/icomoon.eot?d7r1sb#iefix") format("embedded-opentype"), url("../fonts/icons/icomoon.ttf?d7r1sb") format("truetype"), url("../fonts/icons/icomoon.woff?d7r1sb") format("woff"), url("../fonts/icons/icomoon.svg?d7r1sb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow:before {
  content: "\e900"; }

.icon-calendar:before {
  content: "\e901"; }

.icon-coins:before {
  content: "\e902"; }

.icon-inst:before {
  content: "\e903";
  color: #f00; }

.icon-lightning:before {
  content: "\e904"; }

.icon-location:before {
  content: "\e905"; }

.icon-mail:before {
  content: "\e906";
  color: #f00; }

.icon-medal:before {
  content: "\e907";
  color: #ff0505; }

.icon-phone:before {
  content: "\e908";
  color: #fff; }

.icon-star:before {
  content: "\e909"; }

.icon-vk:before {
  content: "\e90a";
  color: #f00; }

@font-face {
  font-family: 'Oswald Light';
  src: url("../fonts/oswald/Oswald-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helvetica_neue/HelveticaNeueCyr-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica Light';
  src: url("../fonts/helvetica_neue/HelveticaNeueCyr-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helvetica_neue/HelveticaNeueCyr-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Geometria Bold';
  src: url("../fonts/geometria/Geometria-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/geometria/Geometria.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Geometria Medium';
  src: url("../fonts/geometria/Geometria-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Geometria Light';
  src: url("../fonts/geometria/Geometria-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/proxima/ProximaNova-Regular.eot");
  src: local("../fonts/proxima/Proxima Nova Regular"), local("ProximaNova-Light"), url("../fonts/proxima/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Regular.woff") format("woff"), url("../fonts/proxima/ProximaNova-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Light';
  src: url("../fonts/proxima/ProximaNova-Light.eot");
  src: local("../fonts/proxima/Proxima Nova Light"), local("ProximaNova-Light"), url("../fonts/proxima/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Light.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Light.woff") format("woff"), url("../fonts/proxima/ProximaNova-Light.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url("../fonts/proxima/ProximaNova-Semibold.eot");
  src: local("../fonts/proxima/Proxima Nova Semibold"), local("ProximaNova-Bold"), url("../fonts/proxima/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Semibold.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Semibold.woff") format("woff"), url("../fonts/proxima/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url("../fonts/proxima/ProximaNova-Bold.eot");
  src: local("../fonts/proxima/Proxima Nova Bold"), local("ProximaNova-Bold"), url("../fonts/proxima/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Bold.woff") format("woff"), url("../fonts/proxima/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Black';
  src: url("../fonts/proxima/ProximaNova-Black.eot");
  src: local("../fonts/proxima/Proxima Nova Black"), local("ProximaNova-Black"), url("../fonts/proxima/ProximaNova-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Black.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Black.woff") format("woff"), url("../fonts/proxima/ProximaNova-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Extrabld';
  src: url("../fonts/proxima/ProximaNova-Extrabld.eot");
  src: local("../fonts/proxima/Proxima Nova Extrabold"), local("ProximaNova-Extrabld"), url("../fonts/proxima/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Extrabld.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Extrabld.woff") format("woff"), url("../fonts/proxima/ProximaNova-Extrabld.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

.rouble {
  font-family: "als_rublregular", sans-serif; }

.statistics {
  display: flex;
  margin: 100px 0 0 -35px; }
  .statistics .item {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0 0 0 35px;
    padding: 50px;
    border: 1px solid #aeaeae;
    border-radius: 20px;
    font-family: "Proxima Nova Semibold"; }
  .statistics .num {
    margin-right: 30px;
    font-size: 91px;
    color: #76a2f3; }
  .statistics .label {
    font-size: 25px;
    line-height: 30px;
    color: #000;
    text-transform: uppercase; }

/***/
.map-wrap {
  padding: 130px 0 0;
  text-align: center; }
  .map-wrap .title {
    max-width: 750px;
    margin: 0 auto; }
  .map-wrap .map {
    margin-top: 100px; }
    .map-wrap .map img {
      width: 100%; }
    .map-wrap .map .mobile {
      display: none; }

/***/
.request-block {
  display: flex;
  align-items: flex-end;
  padding: 113px 0 150px; }
  .request-block .col {
    flex: 1; }
  .request-block .right-col {
    padding: 0 30px; }
  .request-block p {
    max-width: 500px;
    font-size: 34px;
    line-height: 40px; }
  .request-block .btn {
    max-width: 590px;
    height: 110px; }

/***/
.services-block-wrap .services-title {
  max-width: 1230px;
  margin: 0 auto;
  text-align: center; }

.services-block {
  margin: 100px 0 0 0; }
  .services-block .wrap {
    display: flex;
    flex-wrap: wrap;
    margin: -100px 0 0 -100px; }
  .services-block-item {
    flex: 33.333%;
    max-width: 33.333%;
    padding: 100px 0 0 100px;
    overflow: hidden; }
  .services-block .icon {
    margin-bottom: 50px; }
  .services-block .title {
    margin: 0 0 40px;
    font-family: 'Proxima Nova Bold';
    font-size: 34px;
    line-height: 40px; }
  .services-block p {
    margin: 0;
    font-size: 22px;
    line-height: 28px; }

/***/
.total-cost {
  padding: 130px 0 160px;
  text-align: center; }
  .total-cost .cost {
    font-family: 'Proxima Nova Semibold';
    font-size: 210px; }
  .total-cost .text {
    margin-top: 60px;
    font-size: 34px;
    line-height: 50px; }
    .total-cost .text span {
      display: block; }
    .total-cost .text b {
      font-family: 'Proxima Nova Bold'; }

/***/
.advantages-wrap {
  position: relative;
  padding: 140px 0 230px; }
  .advantages-wrap:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #f6f6f6;
    background: -moz-linear-gradient(top, #f6f6f6 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top, #f6f6f6 0%, #ffffff 100%);
    background: linear-gradient(to bottom, #f6f6f6 0%, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0 ); }
  .advantages-wrap:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    background: -moz-linear-gradient(top, #f6f6f6 0%, #ffffff 99%);
    background: -webkit-linear-gradient(top, #f6f6f6 0%, #ffffff 99%);
    background: linear-gradient(to bottom, #f6f6f6 0%, #ffffff 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0 ); }
  .advantages-wrap .advantages-title {
    max-width: 720px; }

.advantages-list-big {
  margin-top: 130px; }
  .advantages-list-big .item {
    display: flex;
    padding-top: 120px; }
    .advantages-list-big .item p {
      font-size: 35px;
      line-height: 45px; }
    .advantages-list-big .item .text {
      max-width: 1210px; }
    .advantages-list-big .item .mobile-block {
      display: none; }
    .advantages-list-big .item.press-left {
      padding-right: 70px; }
      .advantages-list-big .item.press-left .text {
        max-width: 510px;
        margin-left: -60px;
        padding-top: 120px; }
    .advantages-list-big .item.press-right {
      justify-content: flex-end;
      padding: 120px 0 0 70px; }
      .advantages-list-big .item.press-right .text {
        position: relative;
        max-width: 830px;
        margin-right: -550px;
        z-index: 5; }
        .advantages-list-big .item.press-right .text p {
          max-width: 700px; }
        .advantages-list-big .item.press-right .text .button-wrap {
          max-width: 290px;
          margin-top: 290px; }
          .advantages-list-big .item.press-right .text .button-wrap .btn {
            width: 100%;
            margin-top: 20px; }
    .advantages-list-big .item.empty-image {
      margin-top: -40px; }
      .advantages-list-big .item.empty-image .text .title {
        max-width: 560px; }
      .advantages-list-big .item.empty-image .text p {
        max-width: 840px; }
  .advantages-list-big .title {
    font-family: 'Proxima Nova Semibold';
    font-size: 50px;
    line-height: 55px; }
  .advantages-list-big .button-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 200px; }
    .advantages-list-big .button-wrap .label {
      margin-bottom: 30px;
      font-family: 'Proxima Nova Semibold';
      font-size: 24px;
      text-transform: uppercase; }

.advantages-list {
  margin-top: 90px; }
  .advantages-list .item {
    display: flex;
    margin-top: 50px; }
    .advantages-list .item:first-child {
      margin-top: 0; }
  .advantages-list .image {
    flex: 50%;
    padding: 0 120px 0 0; }
    .advantages-list .image.right {
      padding: 0; }
  .advantages-list .text {
    flex: calc(50% - 20px);
    padding: 40px 150px 0 80px; }
  .advantages-list .title {
    font-family: 'Proxima Nova Bold';
    font-size: 32px;
    line-height: 33px; }
  .advantages-list p {
    max-width: 470px;
    margin-top: 40px;
    font-size: 22px;
    line-height: 30px; }

/***/
.steps {
  margin-top: 100px; }
  .steps .wrap {
    display: flex;
    flex-wrap: wrap;
    margin: -80px 0 0 -100px; }
  .steps .item {
    flex: 33.333%;
    padding: 80px 0 0 100px; }
  .steps .image {
    margin-bottom: 40px; }
  .steps .title {
    margin-bottom: 30px;
    font-family: 'Proxima Nova Bold';
    font-size: 24px; }
  .steps p {
    margin: 0;
    font-size: 20px;
    line-height: 28px; }

/***/
.packages-wrap {
  padding: 100px 0 0; }
  .packages-wrap .packages-title {
    max-width: 950px;
    margin: 0 auto;
    font-size: 67px;
    line-height: 64px; }

.packages-list-item {
  position: relative;
  margin-top: 100px;
  padding: 100px 0 0;
  border-top: 1px solid #ccc; }
  .packages-list-item:first-child {
    border: 0; }
  .packages-list-item.active .condition-block {
    opacity: 1;
    pointer-events: all; }
  .packages-list-item.active .profitably.mobile {
    opacity: 0; }
  .packages-list-item .condition-block {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    padding: 30px 0 0;
    background: #fff;
    overflow: auto;
    z-index: 9;
    transition: 0.5s;
    opacity: 0;
    pointer-events: none; }
    .packages-list-item .condition-block .title {
      font-family: 'Proxima Nova Bold';
      font-size: 35px;
      line-height: 40px; }
    .packages-list-item .condition-block p {
      margin-top: 20px;
      font-size: 18px;
      line-height: 26px; }
    .packages-list-item .condition-block .button-wrap {
      display: flex;
      align-items: center;
      margin-top: 30px; }
      .packages-list-item .condition-block .button-wrap .btn {
        margin: 0 20px 0 0;
        padding: 10px 20px;
        font-size: 14px; }
      .packages-list-item .condition-block .button-wrap .close {
        color: #979797;
        font-size: 16px; }

.packages-list .head {
  display: flex; }
  .packages-list .head .left-block .title-wrap {
    display: none; }
  .packages-list .head .right-block {
    flex: 1; }
  .packages-list .head .num {
    font-family: 'Proxima Nova Semibold';
    padding-right: 135px;
    font-size: 230px; }
  .packages-list .head .title-wrap {
    display: flex;
    align-items: flex-end; }
    .packages-list .head .title-wrap .label {
      font-family: 'Proxima Nova Semibold';
      font-size: 25px;
      text-transform: uppercase;
      letter-spacing: 10px;
      color: #4c4c4c; }
    .packages-list .head .title-wrap .title {
      margin-left: 50px;
      font-family: 'Proxima Nova Semibold';
      font-size: 34px;
      line-height: 30px; }
    .packages-list .head .title-wrap .button-wrap {
      display: flex;
      align-items: center;
      align-self: center;
      margin-left: 50px; }
  .packages-list .head .list-benefits {
    display: flex;
    margin-top: 70px; }
    .packages-list .head .list-benefits .item {
      flex: 1;
      padding: 0 0 0 20px;
      border-left: 3px solid #518bf7;
      font-family: 'Proxima Nova Bold';
      font-size: 22px;
      line-height: 25px; }

.packages-list .body {
  display: flex;
  margin-top: 100px; }
  .packages-list .body .left-block {
    flex: 2;
    padding-right: 70px; }
  .packages-list .body .right-block {
    flex: 1; }
  .packages-list .body .alert-text {
    margin-top: 40px; }
    .packages-list .body .alert-text .gray-text {
      margin-top: 10px;
      color: #9b9b9b;
      font-size: 24px; }
      .packages-list .body .alert-text .gray-text:first-child {
        margin-top: 0; }
    .packages-list .body .alert-text .yellow-block {
      display: inline-block;
      margin-top: 10px;
      padding: 4px 16px;
      background: #f7da3a;
      font-size: 22px;
      border-radius: 3px;
      text-transform: uppercase;
      text-align: center; }
      .packages-list .body .alert-text .yellow-block:first-child {
        margin-top: 0; }
  .packages-list .body .cost-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 45px; }
    .packages-list .body .cost-wrap .cost {
      font-family: 'Proxima Nova Semibold';
      font-size: 58px; }
      .packages-list .body .cost-wrap .cost span {
        font-size: 27px; }
    .packages-list .body .cost-wrap .vat {
      padding: 0 0 10px 20px;
      font-family: 'Proxima Nova Light';
      font-size: 17px; }
      .packages-list .body .cost-wrap .vat span {
        display: block; }
  .packages-list .body .button-wrap .condition-btn {
    display: none; }

.packages-list .profitably {
  padding: 5px 20px;
  background: #f7da3a;
  color: #000;
  font-size: 18px;
  text-align: center;
  border-radius: 20px; }
  .packages-list .profitably:first-child {
    margin: 0; }
  .packages-list .profitably.mobile {
    display: none; }

.packages-list .condition-btn {
  display: inline-block;
  margin-left: 18px;
  padding: 5px 20px;
  font-family: 'Proxima Nova Light';
  font-size: 18px;
  color: #000;
  text-align: center;
  border: 1px solid #000;
  border-radius: 20px;
  text-decoration: none; }
  .packages-list .condition-btn:first-child {
    margin: 0; }

/***/
.franchisees-wrap {
  position: relative;
  padding: 100px 0 260px; }
  .franchisees-wrap:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    background: -moz-linear-gradient(top, #f6f6f6 0%, #ffffff 99%);
    background: -webkit-linear-gradient(top, #f6f6f6 0%, #ffffff 99%);
    background: linear-gradient(to bottom, #f6f6f6 0%, #ffffff 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0 ); }
  .franchisees-wrap .franchisees-title {
    max-width: 1230px;
    margin: 0 auto;
    text-align: center; }

.franchisees-list {
  margin-top: 120px; }
  .franchisees-list-item {
    display: flex;
    margin-top: 100px;
    padding-top: 100px;
    border-top: 1px solid #e6e6e6; }
    .franchisees-list-item:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0; }
  .franchisees-list .image {
    flex: 1;
    max-width: 350px;
    padding-right: 130px; }
    .franchisees-list .image .name {
      flex: 100%;
      margin-top: 40px;
      font-family: 'Geometria Medium';
      font-size: 27px; }
      .franchisees-list .image .name span {
        display: block; }
    .franchisees-list .image .town {
      flex: 100%;
      font-family: 'Geometria Medium';
      margin-top: 30px;
      font-size: 21px;
      color: #979797; }
  .franchisees-list .text {
    flex: 1; }
    .franchisees-list .text p {
      margin-top: 30px;
      font-family: 'Geometria';
      font-size: 24px;
      line-height: 35px; }
      .franchisees-list .text p:first-child {
        margin-top: 0; }
    .franchisees-list .text .button-wrap {
      display: none;
      margin-top: 30px; }
      .franchisees-list .text .button-wrap .btn {
        padding: 10px 20px;
        font-family: 'Geometria Medium';
        font-size: 13px; }
  .franchisees-list .franchisees-statistics {
    flex: 1;
    max-width: 290px;
    padding-left: 100px; }
    .franchisees-list .franchisees-statistics .item {
      margin-top: 75px; }
      .franchisees-list .franchisees-statistics .item:first-child {
        margin-top: 0; }
    .franchisees-list .franchisees-statistics .num {
      padding-bottom: 30px;
      font-size: 85px; }
    .franchisees-list .franchisees-statistics .label {
      font-family: 'Proxima Nova Bold';
      font-size: 20px;
      line-height: 27px;
      text-transform: uppercase; }

/***/
.faq-wrap {
  padding-top: 100px; }
  .faq-wrap .spoiler-block {
    margin-top: 130px; }

/***/
/******************************************************************************/
.tabs-head-item {
  cursor: pointer; }
  .tabs-head-item.active {
    padding: 24px;
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 8px; }
    .tabs-head-item.active:active {
      margin-bottom: 0;
      border-width: 1px; }
    .tabs-head-item.active .icon:before {
      color: #000; }
    .tabs-head-item.active span {
      color: #000; }

.tabs-body-item {
  display: none; }
  .tabs-body-item.active {
    display: block; }

.form {
  width: 100%; }
  .form .row {
    display: flex;
    margin: 20px 0 0 -20px; }
    .form .row:first-child {
      margin-top: 0; }
    .form .row .col {
      flex: 1;
      padding-left: 20px; }
  .form .btn {
    width: 100%;
    max-width: none;
    outline: none; }

.input-wrap {
  position: relative; }
  .input-wrap .icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 36px;
    transform: translateY(-50%); }
  .input-wrap .input {
    padding-left: 80px; }

.input {
  width: 100%;
  height: 90px;
  padding: 0 30px;
  font-family: 'Geometria Bold';
  font-size: 18px;
  color: #000;
  border: 0;
  border-radius: 5px;
  outline: none; }
  .input::placeholder {
    color: #000;
    text-transform: uppercase; }

/* Middle
-----------------------------------------------------------------------------*/
.first-block {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 140px;
  padding: 330px 0 176px;
  background-image: url("../img/bg.jpg");
  background-size: cover;
  background-position: center;
  color: #fff;
  overflow: hidden; }
  .first-block .wrap {
    display: flex;
    flex-wrap: wrap; }
  .first-block .left-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2; }
  .first-block .title {
    max-width: 1210px; }
    .first-block .title p {
      margin-top: 60px;
      font-size: 43px; }
  .first-block .form {
    margin-top: 90px; }
  .first-block .mobile-block {
    position: relative;
    display: none;
    margin-top: 230px;
    color: #ffffff;
    text-align: center;
    z-index: 5; }
    .first-block .mobile-block .btn {
      width: 100%;
      padding: 36px 20px;
      font-size: 25px; }
    .first-block .mobile-block .phone {
      display: block;
      margin-top: 60px;
      font-family: 'Proxima Nova Bold';
      font-size: 36px;
      color: #ffffff;
      text-decoration: none; }
    .first-block .mobile-block .free {
      margin-top: 15px;
      font-family: 'Proxima Nova Bold';
      font-size: 20px;
      color: #ffffff; }

/***/
.form-block-wrap {
  position: relative;
  padding: 160px 40px 120px; }
  .form-block-wrap .left-image {
    position: absolute;
    top: 190px;
    left: 0; }
  .form-block-wrap .right-top-image {
    position: absolute;
    top: 0;
    right: 170px; }
  .form-block-wrap .right-image {
    position: absolute;
    top: 180px;
    right: 0; }
  .form-block-wrap .title {
    position: relative;
    margin: 0 auto;
    z-index: 9; }
  .form-block-wrap p {
    max-width: 1400px;
    margin: 90px auto 0; }

.form-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1800px;
  margin: 70px auto 0;
  padding: 120px 0 150px;
  background-size: cover;
  border-radius: 10px; }
  .form-block h2 {
    position: relative;
    z-index: 9; }
  .form-block .label {
    margin-bottom: 60px;
    color: #7c7c7c;
    font-size: 20px; }
  .form-block .form {
    max-width: 613px; }
  .form-block .input {
    height: 110px; }
  .form-block .btn {
    margin-top: 10px;
    padding: 43px 100px; }

/*** Modal ***/
.modal {
  position: relative;
  width: calc(100% - 60px);
  max-width: 800px;
  max-height: 95vh;
  padding: 60px 60px 50px;
  background: #fff;
  border-radius: 5px;
  overflow: auto; }
  .modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    z-index: 9999; }
    .modal-wrap.show {
      opacity: 1;
      pointer-events: all; }
    .modal-wrap .overflow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.8;
      cursor: pointer; }
    .modal-wrap .title {
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 40px; }
  .modal .cross {
    position: absolute;
    top: 50px;
    right: 70px;
    width: 40px;
    height: 40px;
    cursor: pointer; }
    .modal .cross:before, .modal .cross:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 2px;
      margin: 22% 0 0 -10%;
      background: #000;
      transform: rotate(45deg) translate(-50%); }
    .modal .cross:before {
      margin: -26% 0 0 -10%;
      transform: rotate(-45deg) translate(-50%); }
  .modal .input {
    border: 1px solid; }
  .modal p {
    max-width: 700px;
    color: #7c7c7c;
    font-size: 16px;
    line-height: normal; }
  .modal .phone-wrap {
    margin-top: 10px;
    text-align: center; }
    .modal .phone-wrap span {
      display: block;
      margin-bottom: 20px;
      font-family: 'Geometria';
      font-size: 14px;
      text-transform: uppercase; }
    .modal .phone-wrap a {
      font-family: 'Proxima Nova Bold';
      font-size: 38px;
      text-decoration: none;
      color: #000; }

@media (max-width: 561px) {
  .modal {
    padding: 40px 30px; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  font-family: 'Geometria';
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

p {
  margin-top: 70px;
  font-size: 32px;
  line-height: 47px; }
  p:first-child {
    margin-top: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.noscroll {
  overflow: hidden; }

html {
  height: 100%; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

body {
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  height: 100%; }

.wrapper {
  margin: 0 auto;
  min-height: 100%;
  overflow: hidden; }

.container-block {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 70px; }

img {
  max-width: 100%; }

h1, .h1 {
  font-size: 74px;
  font-family: 'Proxima Nova Bold'; }

h2, .h2 {
  font-size: 70px;
  font-family: 'Proxima Nova Bold'; }

h3, .h3 {
  font-size: 58px;
  line-height: 67px;
  font-family: 'Proxima Nova Bold'; }

h4, .h4 {
  font-size: 45px;
  line-height: 50px;
  font-family: 'Proxima Nova Bold'; }

.text-center {
  text-align: center; }

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px 60px;
  background: #518bf7;
  font-family: 'Proxima Nova Bold';
  font-size: 21px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  border: 0;
  border-radius: 50px;
  cursor: pointer; }
  .btn:hover {
    opacity: 0.9; }
  .btn span {
    color: #ffffff;
    text-transform: uppercase; }
  .btn.btn-empty {
    background: none;
    border: 1px solid;
    color: #000; }
  .btn.btn-white {
    background: #fff;
    color: #000; }
    .btn.btn-white.btn-empty {
      background: none;
      border: 1px solid #fff;
      color: #fff; }
  .btn.btn-yellow {
    background: #f7da3a;
    color: #000; }
    .btn.btn-yellow.btn-empty {
      background: none;
      border: 1px solid #f7da3a;
      color: #000; }
  .btn.btn-small {
    padding: 18px 40px;
    font-size: 20px; }

/***/
ul {
  margin-top: 20px; }
  ul:first-child {
    margin-top: 0; }
  ul li {
    margin-top: 10px;
    font-size: 18px; }
    ul li:first-child {
      margin-top: 0; }

/*** Spoiler ***/
.spoiler-block-item {
  margin-top: 35px;
  border: 1px solid #bababa;
  border-radius: 10px; }
  .spoiler-block-item:first-child {
    margin: 0; }
  .spoiler-block-item.active .head .icon {
    justify-content: flex-start;
    transform: rotate(180deg); }
  .spoiler-block-item.active .body {
    display: block; }

.spoiler-block .head {
  display: flex;
  align-items: center;
  padding: 52px 80px;
  font-weight: bold;
  font-size: 18px;
  line-height: normal;
  overflow: hidden;
  cursor: pointer; }
  .spoiler-block .head span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .spoiler-block .head .num {
    width: 100px;
    font-size: 72px;
    line-height: 60px;
    font-weight: 400; }
  .spoiler-block .head .title {
    flex: 1;
    font-family: 'Proxima Nova Semibold';
    font-size: 25px;
    line-height: 37px;
    text-transform: uppercase; }
  .spoiler-block .head .icon {
    display: flex;
    justify-content: flex-end;
    width: 100px; }

.spoiler-block .body {
  display: none;
  margin-top: -5px;
  padding: 0 200px 70px; }
  .spoiler-block .body p {
    font-family: 'Geometria';
    font-size: 24px;
    line-height: 35px; }

/* Header
-----------------------------------------------------------------------------*/
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 40px 0;
  color: #ffffff;
  z-index: 9999; }
  .header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; }
    .header.fixed .logo {
      display: none; }
    .header.fixed .menu-btn .top {
      transform: rotate(-45deg); }
    .header.fixed .menu-btn .middle {
      margin: -4px 0 0 0;
      transform: rotate(45deg); }
    .header.fixed .menu-btn .bottom {
      margin: 0;
      opacity: 0; }
  .header .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1800px;
    min-height: 90px;
    margin: 0 auto; }
  .header .left-block {
    display: flex;
    align-items: center; }
  .header .right-block {
    display: flex;
    align-items: center; }
  .header .btn {
    padding: 13px 20px;
    border-width: 5px;
    font-size: 14px;
    border-radius: 5px; }
    .header .btn:active {
      margin-bottom: 0; }
  .header .contact-wrap {
    display: flex;
    align-items: flex-end; }
    .header .contact-wrap .phone {
      margin-left: 170px;
      font-family: 'Proxima Nova Extrabld';
      font-size: 34px;
      color: #ffffff;
      text-decoration: none; }
    .header .contact-wrap .free {
      margin-left: 70px;
      padding: 0 0 8px;
      font-size: 20px; }
  .header .address-wrap {
    margin-left: 60px;
    padding-left: 30px;
    color: #ffffff; }
    .header .address-wrap .city {
      position: relative;
      margin: 0 0 10px;
      font-family: 'Proxima Nova Extrabld';
      font-size: 14px;
      text-transform: uppercase; }
      .header .address-wrap .city:before {
        content: '/';
        position: absolute;
        top: 0;
        left: -30px;
        font-family: 'Proxima Nova Black';
        font-size: 16px;
        color: #ee0e0e; }

.menu {
  margin: 0 0 0 116px; }
  .menu .item {
    margin: 0 0 0 80px;
    font-family: 'Proxima Nova Extrabld';
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none; }
    .menu .item:first-child {
      margin: 0; }

.menu-btn {
  display: none;
  position: relative; }
  .menu-btn span {
    display: block;
    width: 40px;
    height: 4px;
    margin-top: 10px;
    background: #ffffff;
    transition: 0.3s; }
    .menu-btn span:first-child {
      margin: 0; }

.top-menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #000000e6;
  color: #ffffff;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: 999; }
  .top-menu-mobile.show {
    opacity: 1;
    pointer-events: all; }
  .top-menu-mobile .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 180px);
    margin-top: 100px;
    padding: 0 70px;
    overflow: auto; }
  .top-menu-mobile .logo {
    max-width: 390px;
    margin-bottom: 160px; }
  .top-menu-mobile .phone {
    color: #ffffff;
    font-family: 'Proxima Nova Bold';
    font-size: 46px;
    text-align: left;
    text-decoration: none; }
  .top-menu-mobile .free {
    margin-top: 20px;
    font-size: 24px; }
  .top-menu-mobile .email-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px; }
    .top-menu-mobile .email-wrap a {
      font-family: 'Proxima Nova Bold';
      font-size: 38px; }
    .top-menu-mobile .email-wrap span {
      display: block;
      margin-top: 20px;
      font-size: 24px;
      font-family: 'Proxima Nova Bold'; }
  .top-menu-mobile .button-wrap {
    width: 100%;
    max-width: 504px;
    margin: 140px auto 0; }
  .top-menu-mobile .btn {
    max-width: none;
    margin: 35px auto 0;
    padding: 34px 20px;
    font-size: 25px; }
    .top-menu-mobile .btn.btn-empty {
      border-radius: 10px; }

/*** Media ***/
@media (max-width: 1661px) {
  .header .contact-wrap .phone {
    margin-left: 0; }
  .header .contact-wrap .free {
    margin-left: 40px; }
  .header .menu {
    margin: 0 0 0 30px; }
    .header .menu .item {
      margin: 0 0 0 40px; }
  .header .phone-wrap {
    margin-left: 30px; }
  .header .address-wrap {
    margin-left: 30px;
    padding-left: 20px; }
    .header .address-wrap .city:before {
      left: -20px; } }

@media (max-width: 1441px) {
  .header .contact-wrap .phone {
    font-size: 30px; }
  .header .contact-wrap .free {
    font-size: 18px; } }

@media (max-width: 1301px) {
  .header {
    align-items: flex-start; }
    .header .right-block {
      flex-direction: column-reverse;
      align-items: flex-end; }
      .header .right-block .contact-wrap {
        padding-bottom: 20px; }
      .header .right-block .phone-wrap {
        margin-left: 0; } }

@media (max-width: 1281px) {
  .header .logo {
    max-width: 400px; }
  .header .phone-wrap {
    margin-left: 20px; }
  .header .contact-wrap .phone {
    font-size: 26px; }
  .header .contact-wrap .free {
    margin-left: 20px;
    padding-bottom: 5px; }
  .header .address-wrap {
    margin-left: 20px; }
  .header .menu .item {
    margin-left: 20px; } }

@media (max-width: 1025px) {
  .header .menu,
  .header .contact-wrap,
  .header .btn {
    display: none; }
  .menu-btn {
    display: block; } }

@media (max-width: 561px) {
  .header {
    padding-right: 30px;
    padding-left: 30px; }
    .header .logo {
      font-size: 30px; }
      .header .logo span {
        font-size: 15px; }
      .header .logo .red {
        margin-left: 10px; }
  .top-menu-mobile .wrap {
    padding: 0 20px; }
  .top-menu-mobile .logo {
    margin-bottom: 60px; }
  .top-menu-mobile .phone {
    font-size: 28px; }
  .top-menu-mobile .free {
    margin-top: 10px;
    font-size: 20px; }
  .top-menu-mobile .email-wrap {
    margin-top: 40px; }
    .top-menu-mobile .email-wrap a {
      font-size: 28px; }
    .top-menu-mobile .email-wrap span {
      margin-top: 10px;
      font-size: 20px; }
  .top-menu-mobile .button-wrap {
    margin-top: 30px; }
    .top-menu-mobile .button-wrap .btn {
      padding: 20px 10px;
      font-size: 20px; } }

/* Media
-----------------------------------------------------------------------------*/
@font-face {
  font-family: 'als_rublregular';
  src: url("../fonts/rouble/rouble-webfont.woff2") format("woff2"), url("../fonts/rouble/rouble-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icons/icomoon.eot?d7r1sb");
  src: url("../fonts/icons/icomoon.eot?d7r1sb#iefix") format("embedded-opentype"), url("../fonts/icons/icomoon.ttf?d7r1sb") format("truetype"), url("../fonts/icons/icomoon.woff?d7r1sb") format("woff"), url("../fonts/icons/icomoon.svg?d7r1sb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow:before {
  content: "\e900"; }

.icon-calendar:before {
  content: "\e901"; }

.icon-coins:before {
  content: "\e902"; }

.icon-inst:before {
  content: "\e903";
  color: #f00; }

.icon-lightning:before {
  content: "\e904"; }

.icon-location:before {
  content: "\e905"; }

.icon-mail:before {
  content: "\e906";
  color: #f00; }

.icon-medal:before {
  content: "\e907";
  color: #ff0505; }

.icon-phone:before {
  content: "\e908";
  color: #fff; }

.icon-star:before {
  content: "\e909"; }

.icon-vk:before {
  content: "\e90a";
  color: #f00; }

@font-face {
  font-family: 'Oswald Light';
  src: url("../fonts/oswald/Oswald-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helvetica_neue/HelveticaNeueCyr-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica Light';
  src: url("../fonts/helvetica_neue/HelveticaNeueCyr-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helvetica_neue/HelveticaNeueCyr-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Geometria Bold';
  src: url("../fonts/geometria/Geometria-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/geometria/Geometria.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Geometria Medium';
  src: url("../fonts/geometria/Geometria-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Geometria Light';
  src: url("../fonts/geometria/Geometria-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/proxima/ProximaNova-Regular.eot");
  src: local("../fonts/proxima/Proxima Nova Regular"), local("ProximaNova-Light"), url("../fonts/proxima/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Regular.woff") format("woff"), url("../fonts/proxima/ProximaNova-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Light';
  src: url("../fonts/proxima/ProximaNova-Light.eot");
  src: local("../fonts/proxima/Proxima Nova Light"), local("ProximaNova-Light"), url("../fonts/proxima/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Light.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Light.woff") format("woff"), url("../fonts/proxima/ProximaNova-Light.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url("../fonts/proxima/ProximaNova-Semibold.eot");
  src: local("../fonts/proxima/Proxima Nova Semibold"), local("ProximaNova-Bold"), url("../fonts/proxima/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Semibold.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Semibold.woff") format("woff"), url("../fonts/proxima/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url("../fonts/proxima/ProximaNova-Bold.eot");
  src: local("../fonts/proxima/Proxima Nova Bold"), local("ProximaNova-Bold"), url("../fonts/proxima/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Bold.woff") format("woff"), url("../fonts/proxima/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Black';
  src: url("../fonts/proxima/ProximaNova-Black.eot");
  src: local("../fonts/proxima/Proxima Nova Black"), local("ProximaNova-Black"), url("../fonts/proxima/ProximaNova-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Black.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Black.woff") format("woff"), url("../fonts/proxima/ProximaNova-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Extrabld';
  src: url("../fonts/proxima/ProximaNova-Extrabld.eot");
  src: local("../fonts/proxima/Proxima Nova Extrabold"), local("ProximaNova-Extrabld"), url("../fonts/proxima/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima/ProximaNova-Extrabld.woff2") format("woff2"), url("../fonts/proxima/ProximaNova-Extrabld.woff") format("woff"), url("../fonts/proxima/ProximaNova-Extrabld.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

.rouble {
  font-family: "als_rublregular", sans-serif; }

@media (max-width: 1661px) {
  h1, .h1 {
    font-size: 66px;
    line-height: 66px; }
  h2, .h2 {
    font-size: 50px;
    line-height: 58px; }
  h3, .h3 {
    font-size: 48px;
    line-height: 57px; }
  h4, .h4 {
    font-size: 45px;
    line-height: 50px;
    font-family: 'Proxima Nova Bold'; }
  p {
    margin-top: 40px;
    font-size: 27px;
    line-height: 38px; }
  .request-block p {
    font-size: 30px; }
  .statistics {
    margin-top: 60px; }
    .statistics .item {
      padding: 35px; }
    .statistics .num {
      font-size: 70px; }
    .statistics .label {
      font-size: 20px;
      line-height: 25px; }
  .input-wrap .icon {
    left: 25px; }
  .input-wrap .input {
    padding-left: 60px; }
  .first-block {
    padding-top: 250px; }
    .first-block .title p {
      margin-top: 40px;
      font-size: 36px; }
    .first-block .form {
      margin-top: 40px; }
  .map-wrap {
    padding-top: 80px; }
    .map-wrap .map {
      margin-top: 60px; }
  /***/
  /***/
  .request-block .btn {
    display: inline-block;
    max-width: none;
    height: auto; }
  /***/
  .total-cost {
    padding: 100px 0; }
    .total-cost .text {
      margin-top: 40px;
      font-size: 30px;
      line-height: 46px; }
    .total-cost .cost {
      font-size: 180px; }
  .advantages-list-big {
    margin-top: 0; }
    .advantages-list-big .item {
      padding-top: 80px; }
      .advantages-list-big .item.press-right .text {
        margin-right: 0; }
      .advantages-list-big .item.press-right .button-wrap {
        margin-top: 100px; }
      .advantages-list-big .item.press-right .text .button-wrap {
        margin-top: 100px; }
      .advantages-list-big .item.empty-image {
        margin-top: 40px; }
    .advantages-list-big .button-wrap {
      margin-top: 100px; }
  /***/
  .advantages-block {
    margin-top: 40px; }
  .programs-block {
    margin-left: -50px; }
    .programs-block-item {
      margin-left: 50px; }
      .programs-block-item:hover .text {
        padding: 40px 25px 25px; }
      .programs-block-item .text {
        padding: 40px; }
  .services-block .wrap {
    margin: -50px 0 0 -50px; }
  .services-block-item {
    padding: 50px 0 0 50px; }
  .form-block-wrap .left-image {
    top: 80px; }
    .form-block-wrap .left-image img {
      max-height: 200px; }
  .table-wrap {
    padding: 80px; }
  .map-wrap .info {
    left: 40px; } }

@media (max-width: 1441px) {
  .input {
    height: 64px; }
  .btn {
    padding: 20px 40px; }
  .first-block .title p {
    font-size: 32px; }
  .total-cost .cost {
    font-size: 90px; }
  .total-cost .text {
    margin-top: 30px;
    font-size: 26px;
    line-height: 36px; }
  .advantages-wrap {
    padding-top: 30px; }
    .advantages-wrap:before {
      display: none; }
    .advantages-wrap .advantages-title {
      font-size: 47px;
      line-height: 52px; }
  .advantages-list-big {
    margin-top: 0; }
    .advantages-list-big .item.press-right .text .button-wrap {
      margin-top: 60px; }
    .advantages-list-big .item .title {
      font-size: 45px;
      line-height: 50px; }
    .advantages-list-big .item p {
      font-size: 26px;
      line-height: 35px; }
    .advantages-list-big .button-wrap {
      margin-top: 60px; }
  .form-block-wrap .right-top-image {
    right: 80px; }
  .subscribe-block-wrap .social-block-wrap {
    margin-top: 100px; }
  .map-wrap iframe {
    height: 700px; }
  .map-wrap .info .city {
    margin-bottom: 10px;
    font-size: 40px; }
    .map-wrap .info .city:after {
      font-size: 45px; }
  .map-wrap .info .address-wrap {
    margin-bottom: 10px; }
    .map-wrap .info .address-wrap span {
      font-size: 37px; }
      .map-wrap .info .address-wrap span span {
        font-size: 18px; }
  .map-wrap .info .phone {
    font-size: 55px; }
  .map-wrap .info .btn {
    margin-top: 30px; }
  .map-wrap .info .mail {
    margin-top: 20px;
    font-size: 20px; }
    .map-wrap .info .mail .icon {
      margin-right: 20px;
      font-size: 20px; }
  .packages-wrap .packages-title {
    font-size: 50px;
    line-height: 58px; }
  .packages-list .head .num {
    padding-right: 100px;
    font-size: 200px; }
  .packages-list .head .title-wrap .title {
    font-size: 53px;
    line-height: 45px; }
  .packages-list .head .list-benefits .item {
    padding-right: 40px; }
  /***/
  .franchisees-list {
    margin-top: 80px; }
    .franchisees-list .image {
      max-width: 300px;
      padding-right: 60px; }
    .franchisees-list .franchisees-statistics {
      padding-left: 60px; }
  /***/
  .modal .cross {
    top: 20px;
    right: 20px; } }

@media (max-width: 1281px) {
  h1, .h1 {
    font-size: 60px;
    line-height: 60px; }
  .first-block {
    margin-bottom: 80px;
    padding-top: 200px;
    padding-bottom: 120px; }
    .first-block .left-block {
      max-width: none; }
    .first-block .form .input {
      height: 52px;
      font-size: 14px; }
  .spoiler-block .head {
    padding: 50px; }
    .spoiler-block .head .num {
      padding-right: 20px;
      font-size: 60px; }
    .spoiler-block .head .title {
      padding-right: 20px;
      font-size: 20px;
      line-height: 30px; }
  .spoiler-block .body {
    padding: 0 150px 50px; }
    .spoiler-block .body p {
      font-size: 20px;
      line-height: 30px; }
  p {
    font-size: 23px;
    line-height: 34px; }
  .btn {
    padding: 14px 20px;
    font-size: 16px; }
  .statistics {
    margin: 100px 0 0 -20px; }
    .statistics .item {
      margin: 0 0 0 20px;
      padding: 25px;
      border-radius: 10px; }
    .statistics .num {
      margin-right: 20px;
      font-size: 60px; }
    .statistics .label {
      font-size: 16px;
      line-height: 23px; }
  .services-block .title {
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 32px; }
  .services-block p {
    font-size: 18px; }
  .advantages-list-big .item {
    padding-top: 60px; }
    .advantages-list-big .item p {
      font-size: 21px;
      line-height: 30px; }
  .packages-list {
    margin-top: -100px; }
    .packages-list .head .num {
      padding-right: 70px;
      font-size: 150px; }
    .packages-list .head .title-wrap .label {
      font-size: 20px;
      letter-spacing: 7px; }
    .packages-list .head .title-wrap .title {
      margin-left: 30px;
      font-size: 43px;
      line-height: 37px; }
    .packages-list .head .title-wrap .button-wrap {
      margin-left: 30px; }
    .packages-list .head .title-wrap .profitably {
      font-size: 16px;
      line-height: normal; }
    .packages-list .head .title-wrap .condition-btn {
      font-size: 16px;
      line-height: normal; }
    .packages-list .head .list-benefits .item {
      padding-right: 20px;
      font-size: 18px;
      line-height: 22px; }
    .packages-list .body {
      margin-top: 60px; }
      .packages-list .body .alert-text {
        margin-top: 20px; }
        .packages-list .body .alert-text .gray-text {
          font-size: 18px; }
        .packages-list .body .alert-text .yellow-block {
          font-size: 18px; }
      .packages-list .body .cost-wrap {
        margin-bottom: 30px; }
        .packages-list .body .cost-wrap .cost {
          font-size: 45px; }
        .packages-list .body .cost-wrap .vat {
          font-size: 14px; }
    .packages-list .profitably {
      padding: 5px 20px; }
  .franchisees-list .image .name {
    margin-top: 20px;
    font-size: 27px;
    line-height: 30px; }
    .franchisees-list .image .name span {
      display: inline; }
  .franchisees-list .image .town {
    margin-top: 20px;
    font-size: 20px; }
  .franchisees-list .text p {
    font-size: 23px;
    line-height: 34px; }
  .franchisees-list .franchisees-statistics .num {
    font-size: 78px;
    line-height: 60px; }
  .franchisees-list .franchisees-statistics .label {
    font-size: 20px;
    line-height: 23px; }
  .faq-wrap {
    padding-top: 60px; }
    .faq-wrap .spoiler-block {
      margin-top: 60px; }
  .form-block-wrap {
    padding-bottom: 40px; }
    .form-block-wrap .left-image img {
      max-height: 160px; }
    .form-block-wrap .right-top-image img {
      max-width: 160px; }
    .form-block-wrap .right-image {
      display: none; }
  .form-block {
    padding: 70px 20px; }
    .form-block .form {
      max-width: none; }
      .form-block .form .row {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center; }
      .form-block .form .col {
        width: 100%;
        flex: 100%;
        max-width: 500px;
        margin-top: 20px; }
        .form-block .form .col:first-child {
          margin-top: 0; }
      .form-block .form .input {
        height: 70px;
        font-size: 14px; }
      .form-block .form .btn {
        padding: 20px; } }

@media (max-width: 1025px) {
  h1, .h1 {
    font-size: 56px;
    line-height: 60px; }
  h2, .h2 {
    font-size: 40px;
    line-height: 45px; }
  h3, .h3 {
    font-size: 35px;
    line-height: 45px; }
  h4, .h4 {
    font-size: 35px;
    line-height: 40px; }
  p {
    font-size: 28px;
    line-height: 38px; }
  .btn.btn-small {
    padding: 5px 20px;
    font-size: 14px; }
  .first-block .title p {
    margin-top: 30px;
    font-size: 41px;
    line-height: 41px; }
  .map-wrap .map {
    margin-top: 45px; }
  .statistics {
    flex-wrap: wrap;
    margin-top: 60px; }
    .statistics .item {
      flex: calc(50% - 20px);
      max-width: calc(50% - 20px);
      margin-top: 20px; }
  .advantages-wrap {
    padding-bottom: 150px; }
  .advantages-block-item {
    flex: 50%;
    max-width: 50%; }
  .advantages-list .image {
    padding: 0; }
  .advantages-list .text {
    padding: 20px; }
  .advantages-list-big .item.press-right .image {
    margin: 0 -71px 0 -75px; }
  .advantages-list-big .item.press-right .text .button-wrap {
    max-width: none;
    flex-direction: row;
    justify-content: center;
    margin-left: -15px; }
    .advantages-list-big .item.press-right .text .button-wrap .label {
      margin-left: 15px; }
    .advantages-list-big .item.press-right .text .button-wrap .btn {
      flex: calc(50% - 15px);
      max-width: 260px;
      margin-left: 15px;
      padding: 15px 20px;
      font-family: 'Proxima Nova';
      font-size: 20px; }
  .advantages-list-big .item.press-right .image {
    display: none; }
  .advantages-list-big .item.press-right .mobile-block,
  .advantages-list-big .item.press-right .mobile-block .image {
    display: block; }
  .advantages-list-big .item.press-right .button-wrap {
    flex-wrap: wrap; }
    .advantages-list-big .item.press-right .button-wrap .label {
      flex: 100%;
      text-align: center; }
  .advantages-list-big .item .title {
    font-size: 42px;
    line-height: 44px; }
  .advantages-list-big .item p {
    margin-top: 40px; }
  .advantages-list-big .button-wrap {
    max-width: 190px; }
    .advantages-list-big .button-wrap .label {
      margin-bottom: 10px;
      font-size: 16px; }
  .request-block {
    padding: 100px 0; }
    .request-block .right-col {
      display: flex;
      justify-content: flex-end; }
    .request-block p {
      font-size: 26px;
      line-height: 32px; }
  .services-block {
    margin: 80px 0 0 0; }
  .services-block .wrap {
    margin-left: -30px; }
  .services-block-item {
    padding: 50px 0 0 30px; }
  .total-cost {
    padding: 60px 0; }
  .steps {
    margin-top: 60px; }
    .steps .wrap {
      margin: -40px 0 0 -40px; }
    .steps .item {
      padding: 40px 0 0 40px; }
  .packages-wrap {
    padding: 40px 0 0; }
    .packages-wrap .packages-title {
      font-size: 40px;
      line-height: 45px; }
  .packages-list .head .list-benefits {
    flex-wrap: wrap;
    margin-top: 50px; }
    .packages-list .head .list-benefits .item {
      flex: calc(50% - 20px);
      margin: 20px 20px 0 0;
      padding: 0 0 0 20px; }
  .packages-list .body {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px; }
    .packages-list .body .right-block {
      width: 100%;
      padding-top: 40px; }
    .packages-list .body p {
      font-size: 18px;
      line-height: 24px; }
    .packages-list .body .alert-text {
      margin-top: 15px; }
      .packages-list .body .alert-text .yellow-block {
        padding: 2px 5px;
        font-size: 12px; } }

@media (max-width: 769px) {
  .btn {
    padding: 36px 30px;
    font-size: 24px; }
  .first-block {
    background-position: right; }
    .first-block:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.50;
      z-index: 1; }
    .first-block .wrap {
      position: relative;
      z-index: 5; }
    .first-block .form {
      display: none; }
    .first-block .mobile-block {
      display: block; }
  /***/
  .request-block {
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 0; }
    .request-block .right-col {
      padding: 30px 0 0; }
    .request-block p {
      margin-top: 30px; }
  /***/
  .spoiler-block .head {
    padding: 30px; }
    .spoiler-block .head .num {
      display: none; }
    .spoiler-block .head .title {
      font-size: 15px;
      line-height: 20px;
      font-family: 'Proxima Nova Bold'; }
    .spoiler-block .head .icon {
      width: 30px; }
  .spoiler-block .body {
    padding: 0 30px 50px; }
    .spoiler-block .body p {
      font-size: 16px;
      line-height: 25px; }
  .first-block {
    padding-top: 160px; }
    .first-block .form .row {
      flex-wrap: wrap; }
      .first-block .form .row .col {
        flex: 100%;
        margin-top: 20px; }
        .first-block .form .row .col:first-child {
          margin-top: 0; }
  .statistics .item {
    flex: calc(100% - 20px);
    max-width: calc(100% - 20px);
    justify-content: center; }
  .statistics .num {
    width: 35%;
    margin-right: 26px;
    font-size: 80px;
    text-align: right; }
  .statistics .label {
    max-width: 200px;
    font-family: 'Proxima Nova Bold';
    font-size: 23px;
    line-height: 28px; }
  .advantages-wrap {
    padding-bottom: 50px; }
    .advantages-wrap:after {
      display: none; }
    .advantages-wrap .advantages-title {
      margin-bottom: 40px; }
  .advantages-block {
    margin-left: -40px; }
    .advantages-block-item {
      padding: 40px 0 0 40px; }
    .advantages-block .title {
      font-size: 31px; }
    .advantages-block p {
      font-size: 13px; }
  .advantages-list .item {
    flex-direction: column;
    margin-top: 30px; }
    .advantages-list .item:nth-child(2n) {
      flex-direction: column-reverse; }
    .advantages-list .item .image {
      position: relative;
      margin: 0 -20px;
      z-index: 1; }
    .advantages-list .item .text {
      position: relative;
      padding: 0 0 10px;
      z-index: 5; }
  .advantages-list .title {
    font-size: 25px;
    line-height: 30px; }
  .advantages-list p {
    margin-top: 20px;
    font-size: 18px;
    line-height: 25px; }
  .advantages-list + .advantages-list-big {
    margin-top: 30px; }
  .advantages-list-big .item {
    display: block;
    padding-top: 40px; }
    .advantages-list-big .item:first-child {
      padding-top: 0; }
    .advantages-list-big .item.press-left {
      padding: 0; }
      .advantages-list-big .item.press-left .image {
        max-width: 100%; }
      .advantages-list-big .item.press-left .text {
        max-width: none;
        margin: -20px 0 0;
        padding: 20px 70px; }
    .advantages-list-big .item.press-right {
      display: flex;
      flex-direction: column;
      padding: 0; }
      .advantages-list-big .item.press-right .text {
        max-width: none;
        margin-top: 20px;
        padding: 20px 70px; }
        .advantages-list-big .item.press-right .text .button-wrap {
          margin-top: 50px; }
    .advantages-list-big .item.empty-image {
      margin-top: 0;
      padding: 50px 0 0; }
    .advantages-list-big .item p {
      font-size: 30px;
      line-height: 40px; }
  .advantages-list-big .button-wrap {
    max-width: none;
    margin-top: 80px; }
    .advantages-list-big .button-wrap .label {
      font-family: 'Proxima Nova Semibold';
      font-size: 24px; }
    .advantages-list-big .button-wrap .btn {
      width: 100%;
      max-width: 500px;
      padding: 36px 30px;
      font-size: 24px; }
  .form-block .label {
    max-width: 270px;
    margin: 0 auto 50px;
    text-align: center; }
  .services-block-wrap .services-title {
    text-align: left; }
  .services-block-item {
    flex: 100%;
    max-width: 100%; }
  .services-block .icon {
    display: none; }
  .services-block .title {
    padding-left: 32px;
    font-size: 23px;
    line-height: 26px;
    border-left: 5px solid #518bf7; }
  .services-block p {
    font-size: 16px;
    line-height: 20px; }
  .subscribe-block-wrap {
    padding-top: 40px; }
  /***/
  .steps .item {
    flex: 50%;
    max-width: 50%; }
  .steps .image {
    margin-bottom: 30px; }
  .steps .title {
    margin-bottom: 20px;
    font-size: 18px; }
  .steps p {
    font-size: 16px;
    line-height: 24px; }
  /***/
  .packages-wrap {
    padding: 20px 0 0; }
  .packages-list {
    margin: 20px 0 0; }
    .packages-list-item {
      margin-top: 30px;
      padding: 60px 30px;
      border: 1px solid #bababa;
      border-radius: 10px; }
      .packages-list-item:first-child {
        border: 1px solid #bababa; }
    .packages-list .head {
      flex-wrap: wrap; }
      .packages-list .head .left-block {
        display: flex;
        flex: 100%; }
        .packages-list .head .left-block .title-wrap {
          display: block;
          padding-left: 50px; }
      .packages-list .head .right-block .title-wrap {
        display: none; }
      .packages-list .head .num {
        padding: 0;
        font-size: 115px;
        line-height: 84px; }
      .packages-list .head .title {
        margin-left: 20px;
        font-size: 35px; }
      .packages-list .head .title-wrap {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start; }
        .packages-list .head .title-wrap .label {
          font-size: 18px; }
        .packages-list .head .title-wrap .title {
          margin: 10px 0 0;
          font-size: 34px; }
        .packages-list .head .title-wrap .button-wrap {
          flex-direction: column;
          align-items: flex-start;
          align-self: flex-start;
          margin: 20px 0 0; }
        .packages-list .head .title-wrap .condition-btn {
          margin: 10px 0 0; }
    .packages-list .body {
      margin-top: 40px; }
      .packages-list .body .left-block {
        flex: 1;
        padding-right: 20px; }
      .packages-list .body .cost-wrap {
        justify-content: flex-start; }
        .packages-list .body .cost-wrap .cost {
          font-size: 38px; }
      .packages-list .body .alert-text .yellow-block {
        font-size: 14px; }
      .packages-list .body .button-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -20px; }
        .packages-list .body .button-wrap .condition-btn {
          display: block; }
      .packages-list .body .btn {
        margin-left: 20px;
        padding: 13px 30px;
        font-size: 15px; }
      .packages-list .body .condition-btn {
        margin-left: 20px;
        padding: 13px 30px; }
    .packages-list .profitably.mobile {
      display: block;
      position: absolute;
      top: -12px;
      right: 25px;
      font-size: 14px; }
  /***/
  .franchisees-wrap {
    padding-bottom: 40px; }
    .franchisees-wrap:after {
      display: none; }
  .franchisees-list-item {
    flex-wrap: wrap;
    margin-top: 50px;
    padding-top: 0;
    border: 0; }
    .franchisees-list-item .image {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 100%;
      max-width: none;
      padding: 0;
      order: 1; }
      .franchisees-list-item .image img {
        max-width: 130px; }
      .franchisees-list-item .image .name-wrap {
        padding-left: 40px; }
      .franchisees-list-item .image .name {
        margin: 0; }
      .franchisees-list-item .image .town {
        margin: 10px 0 0; }
    .franchisees-list-item .text {
      flex: 100%;
      padding: 40px 0 0;
      order: 3; }
      .franchisees-list-item .text.active .mobile-block {
        display: block; }
      .franchisees-list-item .text.active .button-wrap {
        display: none; }
      .franchisees-list-item .text p {
        font-size: 20px;
        line-height: 30px; }
      .franchisees-list-item .text .mobile-block {
        display: none;
        margin-top: 20px; }
      .franchisees-list-item .text .button-wrap {
        display: flex;
        justify-content: center; }
    .franchisees-list-item .franchisees-statistics {
      display: flex;
      flex: 100%;
      max-width: none;
      margin-left: -70px;
      padding: 40px 0 0;
      order: 2; }
      .franchisees-list-item .franchisees-statistics .item {
        max-width: none;
        margin-top: 0;
        padding: 0 0 0 70px; }
  /***/
  .faq-wrap {
    padding-top: 20px; }
    .faq-wrap .spoiler-block {
      margin-top: 40px; }
  /***/
  .map-wrap .map img {
    display: none; }
    .map-wrap .map img.mobile {
      display: block; }
  /***/
  .form-block-wrap {
    padding: 60px 70px 20px; }
    .form-block-wrap p {
      margin-top: 30px; }
    .form-block-wrap .title {
      max-width: 330px;
      margin: 0 auto; }
  .form-block {
    margin: 70px -95px 0;
    padding: 80px 0 450px;
    background-position: right; }
    .form-block .form .btn {
      padding: 25px 20px;
      font-size: 20px; }
  /***/
  .modal {
    padding: 50px; }
    .modal-wrap .title {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 36px; }
    .modal .cross {
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px; }
    .modal .form .row {
      flex-wrap: wrap; }
    .modal .form .col {
      flex: 100%;
      margin-top: 20px; }
      .modal .form .col:first-child {
        margin-top: 0; }
    .modal .form .btn {
      padding: 15px 20px;
      font-size: 17px; }
    .modal .phone-wrap {
      margin-top: 20px; }
      .modal .phone-wrap span {
        margin-bottom: 10px;
        font-size: 14px; }
      .modal .phone-wrap a {
        font-size: 30px; } }

@media (max-width: 561px) {
  .container-block,
  .content-block {
    padding: 0 20px; }
  h1, .h1 {
    font-size: 32px;
    line-height: 40px; }
  h2, .h2 {
    font-size: 28px;
    line-height: 34px; }
  h3, .h3 {
    font-size: 22px;
    line-height: 26px; }
  p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 26px; }
  .btn {
    padding: 20px;
    font-size: 16px; }
  .spoiler-block-item {
    margin-top: 20px; }
  .spoiler-block .head {
    padding: 20px; }
    .spoiler-block .head .num {
      width: 50px;
      font-size: 30px; }
    .spoiler-block .head .title {
      font-size: 14px;
      line-height: 22px; }
    .spoiler-block .head .icon {
      width: 30px; }
  .spoiler-block .body {
    padding: 0 20px 30px; }
    .spoiler-block .body p {
      font-size: 14px;
      line-height: 22px; }
  .first-block {
    margin-bottom: 60px;
    padding-top: 130px;
    padding-bottom: 80px; }
    .first-block .title p {
      font-size: 20px;
      line-height: 25px; }
    .first-block .mobile-block {
      margin-top: 60px; }
      .first-block .mobile-block .btn {
        padding: 20px 10px;
        font-size: 20px; }
  /***/
  .statistics {
    margin-top: 20px; }
    .statistics .num {
      margin-right: 20px;
      font-size: 50px; }
    .statistics .label {
      font-size: 16px;
      line-height: 20px; }
  /***/
  .map-wrap {
    padding-top: 60px; }
  /***/
  .services-block {
    margin: 60px 0 0 0; }
    .services-block-item {
      flex: 100%;
      max-width: 100%; }
    .services-block .icon {
      margin-bottom: 30px; }
  /***/
  .total-cost .cost {
    font-size: 50px; }
  .total-cost .text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 25px; }
  /***/
  .advantages-wrap {
    padding: 60px 0 130px; }
    .advantages-wrap .advantages-title {
      font-size: 34px;
      line-height: 40px; }
  .advantages-list-big .item .title {
    font-size: 35px; }
  .advantages-list-big .item p {
    font-size: 20px;
    line-height: 30px; }
  .advantages-list-big .item.press-left .text {
    padding: 20px; }
  .advantages-list-big .item.press-right .image {
    margin: 0 -21px; }
  .advantages-list-big .item.press-right .text {
    padding: 20px; }
    .advantages-list-big .item.press-right .text .button-wrap {
      margin: 40px 0 0; }
      .advantages-list-big .item.press-right .text .button-wrap .label {
        margin: 0 0 10px;
        font-size: 20px; }
      .advantages-list-big .item.press-right .text .button-wrap .btn {
        padding: 10px;
        font-size: 16px; }
  .advantages-list-big .button-wrap {
    margin-top: 40px; }
    .advantages-list-big .button-wrap .btn {
      padding: 20px;
      font-size: 16px; }
  .advantages-list {
    margin-top: 60px; }
    .advantages-list .item {
      flex-direction: column;
      margin-top: 20px; }
      .advantages-list .item:nth-child(2n) {
        flex-direction: column-reverse; }
    .advantages-list .text {
      margin-top: 0;
      padding: 0 0 20px; }
    .advantages-list .title {
      font-size: 18px;
      line-height: 26px; }
    .advantages-list p {
      margin-top: 15px;
      font-size: 16px;
      line-height: 22px; }
  /***/
  .steps .wrap {
    margin: -20px 0 0 -20px; }
  .steps .item {
    flex: 100%;
    max-width: 100%;
    padding: 20px 0 0 20px; }
  .steps .image {
    margin-bottom: 20px; }
  /***/
  .packages-wrap {
    padding: 0; }
    .packages-wrap .packages-title {
      font-size: 28px;
      line-height: 35px; }
  .packages-list-item {
    margin-top: 40px;
    padding: 40px 20px; }
  .packages-list .head .left-block {
    flex-direction: column; }
    .packages-list .head .left-block .title-wrap {
      padding: 10px 0 0; }
  .packages-list .head .num {
    font-size: 100px; }
  .packages-list .head .title-wrap .title {
    font-size: 35px; }
  .packages-list .head .list-benefits {
    margin-top: 30px; }
  .packages-list .body {
    flex-direction: column;
    margin-top: 30px; }
    .packages-list .body .left-block {
      padding: 0 0 20px; }
    .packages-list .body .cost-wrap .cost {
      font-size: 30px; }
    .packages-list .body .button-wrap {
      margin: 0 0 0 -10px; }
      .packages-list .body .button-wrap .condition-btn {
        flex: calc(50% - 10px);
        margin-left: 10px;
        padding: 10px; }
      .packages-list .body .button-wrap .btn {
        flex: calc(50% - 10px);
        margin-left: 10px;
        padding: 10px; }
  /***/
  .franchisees-list-item {
    margin-top: 50px;
    padding-top: 50px; }
    .franchisees-list-item .image {
      flex-direction: column;
      align-items: flex-start; }
      .franchisees-list-item .image .name-wrap {
        padding-left: 0; }
      .franchisees-list-item .image .name {
        flex: 100%;
        padding-left: 0; }
      .franchisees-list-item .image .town {
        flex: 100%;
        padding-left: 0; }
    .franchisees-list-item .text p {
      font-size: 16px;
      line-height: 26px; }
    .franchisees-list-item img {
      margin-bottom: 20px; }
    .franchisees-list-item .franchisees-statistics {
      flex-direction: column;
      margin: 0;
      padding: 20px 0 0; }
      .franchisees-list-item .franchisees-statistics .item {
        padding: 40px 0 0; }
  /***/
  .form-block-wrap {
    padding: 60px 20px 20px; }
  .form-block {
    margin: 70px -30px 0;
    padding: 40px 30px; }
    .form-block .container-block {
      padding: 0; }
    .form-block .form .btn {
      padding: 20px;
      font-size: 16px; }
  /***/
  .modal {
    padding: 40px 30px; }
    .modal .title:before {
      display: none; } }

@media (max-width: 375px) {
  .packages-list .body .button-wrap {
    margin: 0; }
    .packages-list .body .button-wrap .condition-btn {
      flex: 100%;
      margin-left: 0;
      padding: 10px; }
    .packages-list .body .button-wrap .btn {
      flex: 100%;
      margin: 10px 0 0; }
  .advantages-list-big .item.press-right .text .button-wrap .btn {
    flex: 100%;
    margin: 10px 0 0; }
    .advantages-list-big .item.press-right .text .button-wrap .btn:first-child {
      margin-top: 0; } }

/* Footer
-----------------------------------------------------------------------------*/
.footer {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1880px;
  margin: 0 auto;
  padding: 0 40px 90px; }
  .footer .col {
    flex: 1; }
  .footer .right-col {
    display: flex;
    justify-content: flex-end; }
  .footer .phone {
    font-family: 'Proxima Nova Bold';
    font-size: 26px; }
    .footer .phone a {
      color: #000;
      text-decoration: none; }
    .footer .phone span {
      display: block;
      margin-top: 7px;
      font-family: 'Proxima Nova';
      font-size: 20px;
      color: #a0a0a0; }
  .footer .btn {
    display: flex;
    min-height: 50px;
    padding: 0 20px;
    font-size: 14px;
    border-radius: 5px; }
    .footer .btn:active {
      margin-bottom: 0; }

@media (max-width: 1441px) {
  .footer {
    padding: 40px; } }

@media (max-width: 1281px) {
  .footer .phone {
    font-size: 38px; }
    .footer .phone span {
      font-size: 20px; } }

@media (max-width: 769px) {
  .footer {
    flex-direction: column; }
    .footer .left-col {
      padding: 0 0 30px;
      text-align: center; }
    .footer .btn {
      min-height: 68px;
      padding: 0 30px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      color: #a3a3a3;
      border-color: #a3a3a3; } }

@media (max-width: 561px) {
  .footer {
    padding: 40px 20px; }
    .footer .phone {
      font-size: 30px; }
      .footer .phone span {
        font-size: 15px; } }
