/* Footer
-----------------------------------------------------------------------------*/

.footer {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1880px;
    margin: 0 auto;
    padding: 0 40px 90px;

    .col {
        flex: 1;
    }
    .right-col {
        display: flex;
        justify-content: flex-end;
    }

    .phone {
        font-family: 'Proxima Nova Bold';
        font-size: 26px;

        a {
            color: #000;
            text-decoration: none;
        }

        span {
            display: block;
            margin-top: 7px;
            font-family: 'Proxima Nova';
            font-size: 20px;
            color: #a0a0a0;
        }
    }

    .btn {
        display: flex;
        min-height: 50px;
        padding: 0 20px;
        font-size: 14px;
        border-radius: 5px;

        &:active {
            margin-bottom: 0;
        }
    }
}


@media (max-width: 1441px) {
    .footer {
        padding: 40px;
    }
}

@media (max-width: 1281px) {
    .footer {
        .phone {
            font-size: 38px;

            span {
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 769px) {
    .footer {
        flex-direction: column;

        .left-col {
            padding: 0 0 30px;
            text-align: center;
        }
        .btn {
            min-height: 68px;
            padding: 0 30px;
            font-family: 'Proxima Nova Semibold';
            font-size: 16px;
            color: #a3a3a3;
            border-color: #a3a3a3;
        }
    }
}

@media (max-width: 561px) {
    .footer {
        padding: 40px 20px;

        .phone {
            font-size: 30px;

            span {
                font-size: 15px;
            }
        }
    }
}