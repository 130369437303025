@import '_var.scss';

.statistics {
    display: flex;
    margin: 100px 0 0 -35px;

    .item {
        display: flex;
        align-items: center;
        flex: 1;
        margin: 0 0 0 35px;
        padding: 50px;
        border: 1px solid #aeaeae;
        border-radius: 20px;
        font-family: "Proxima Nova Semibold";
    }

    .num {
        margin-right: 30px;
        font-size: 91px;
        color: #76a2f3;
    }
    .label {
        font-size: 25px;
        line-height: 30px;
        color: #000;
        text-transform: uppercase;
    }
}

/***/

.map-wrap {
    padding: 130px 0 0;
    text-align: center;

    .title {
        max-width: 750px;
        margin: 0 auto;
    }

    .map {
        margin-top: 100px;

		img {
			width: 100%;
		}

        .mobile {
            display: none;
        }
    }
}

/***/

.request-block {
	display: flex;
	align-items: flex-end;
	padding: 113px 0 150px;

	.col {
		flex: 1;
	}

	.right-col {
		padding: 0 30px;
	}

	p {
		max-width: 500px;
		font-size: 34px;
		line-height: 40px;
	}

	.btn {
		max-width: 590px;
		height: 110px;
	}
}


/***/

.services-block-wrap {
	.services-title {
		max-width: 1230px;
		margin: 0 auto;
		text-align: center;
	}
}

.services-block {
	margin: 100px 0 0 0;

	.wrap {
		display: flex;
		flex-wrap: wrap;
		margin: -100px 0 0 -100px;
	}

	&-item {
		flex: 33.333%;
		max-width: 33.333%;
		padding: 100px 0 0 100px;
		overflow: hidden;
	}

	.icon {
		margin-bottom: 50px;
	}

	.title {
		margin: 0 0 40px;
		font-family: 'Proxima Nova Bold';
		font-size: 34px;
		line-height: 40px;
	}

	p {
		margin: 0;
		font-size: 22px;
		line-height: 28px;
	}
}

/***/

.total-cost {
	padding: 130px 0 160px;
	text-align: center;

	.cost {
        font-family: 'Proxima Nova Semibold';
		font-size: 210px;
	}

	.text {
		margin-top: 60px;
		font-size: 34px;
		line-height: 50px;

		span {
			display: block;
		}

		b {
			font-family: 'Proxima Nova Bold';
		}
	}
}


/***/

.advantages-wrap {
	position: relative;
	padding: 140px 0 230px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: #f6f6f6;
		background: -moz-linear-gradient(top, #f6f6f6 0%, #ffffff 100%);
		background: -webkit-linear-gradient(top, #f6f6f6 0%,#ffffff 100%);
		background: linear-gradient(to bottom, #f6f6f6 0%,#ffffff 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0 );
	}
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: #ffffff;
		background: -moz-linear-gradient(top,  #f6f6f6 0%, #ffffff 99%);
		background: -webkit-linear-gradient(top,  #f6f6f6 0%,#ffffff 99%);
		background: linear-gradient(to bottom,  #f6f6f6 0%,#ffffff 99%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0 );
	}

	.advantages-title {
		max-width: 720px;
	}
}

.advantages-list-big {
	margin-top: 130px;

	.item {
		display: flex;
		padding-top: 120px;

        p {
            font-size: 35px;
            line-height: 45px;
        }

        .text {
            max-width: 1210px;
        }

        .mobile-block {
            display: none;
        }

		&.press-left {
			padding-right: 70px;

			.text {
				max-width: 510px;
				margin-left: -60px;
				padding-top: 120px;
			}
		}

		&.press-right {
			justify-content: flex-end;
			padding: 120px 0 0 70px;

			.text {
				position: relative;
				max-width: 830px;
				margin-right: -550px;
				z-index: 5;

				p {
					max-width: 700px;
				}

				.button-wrap {
					max-width: 290px;
					margin-top: 290px;

					.btn {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}

		&.empty-image {
			margin-top: -40px;

			.text {
				.title {
					max-width: 560px;
				}
				p {
					max-width: 840px;
				}
			}
		}
	}

	.title {
		font-family: 'Proxima Nova Semibold';
		font-size: 50px;
		line-height: 55px;
	}
	.button-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 200px;

		.label {
			margin-bottom: 30px;
			font-family: 'Proxima Nova Semibold';
			font-size: 24px;
			text-transform: uppercase;
		}
	}
}

.advantages-list {
	margin-top: 90px;

	.item {
		display: flex;
		margin-top: 50px;

		&:first-child {
			margin-top: 0;
		}
	}

	.image {
		flex: 50%;
		padding: 0 120px 0 0;

		&.right {
			padding: 0;
		}
	}
	.text {
		flex: calc(50% - 20px);
		padding: 40px 150px 0 80px;
	}

	.title {
		font-family: 'Proxima Nova Bold';
		font-size: 32px;
		line-height: 33px;
	}
	p {
        max-width: 470px;
		margin-top: 40px;
		font-size: 22px;
		line-height: 30px;
	}
}

/***/

.steps {
    margin-top: 100px;

    .wrap {
        display: flex;
        flex-wrap: wrap;
        margin: -80px 0 0 -100px;
    }

    .item {
        flex: 33.333%;
        padding: 80px 0 0 100px;
    }

    .image {
        margin-bottom: 40px;
    }

    .title {
        margin-bottom: 30px;
        font-family: 'Proxima Nova Bold';
        font-size: 24px;
    }

    p {
        margin: 0;
        font-size: 20px;
        line-height: 28px;
    }
}

/***/

.packages-wrap {
    padding: 100px 0 0;

    .packages-title {
        max-width: 950px;
        margin: 0 auto;
        font-size: 67px;
        line-height: 64px;
    }
}

.packages-list {
    &-item {
        position: relative;
        margin-top: 100px;
        padding: 100px 0 0;
        border-top: 1px solid #ccc;

        &:first-child {
            border: 0;
        }

        &.active {
            .condition-block {
                opacity: 1;
                pointer-events: all;
            }

            .profitably {
                &.mobile {
                    opacity: 0;
                }
            }
        }

        .condition-block {
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            padding: 30px 0 0;
            background: #fff;
            overflow: auto;
            z-index: 9;
            transition: 0.5s;
            opacity: 0;
            pointer-events: none;

            .title {
                font-family: 'Proxima Nova Bold';
                font-size: 35px;
                line-height: 40px;
            }

            p {
                margin-top: 20px;
                font-size: 18px;
                line-height: 26px;
            }

            .button-wrap {
                display: flex;
                align-items: center;
                margin-top: 30px;

                .btn {
                    margin: 0 20px 0 0;
                    padding: 10px 20px;
                    font-size: 14px;
                }

                .close {
                    color: #979797;
                    font-size: 16px;
                }
            }
        }
    }

    .head {
        display: flex;

        .left-block {
            .title-wrap {
                display: none;
            }
        }

        .right-block {
            flex: 1;
        }

        .num {
            font-family: 'Proxima Nova Semibold';
            padding-right: 135px;
            font-size: 230px;
        }

        .title-wrap {
            display: flex;
            align-items: flex-end;

            .label {
                font-family: 'Proxima Nova Semibold';
                font-size: 25px;
                text-transform: uppercase;
                letter-spacing: 10px;
                color: #4c4c4c;
            }

            .title {
                margin-left: 50px;
                font-family: 'Proxima Nova Semibold';
                font-size: 34px;
                line-height: 30px;
            }

            .button-wrap {
                display: flex;
                align-items: center;
                align-self: center;
                margin-left: 50px;
            }
        }

        .list-benefits {
            display: flex;
            margin-top: 70px;

            .item {
                flex: 1;
                padding: 0 0 0 20px;
                border-left: 3px solid #518bf7;
                font-family: 'Proxima Nova Bold';
                font-size: 22px;
                line-height: 25px;
            }
        }
    }

    .body {
        display: flex;
        margin-top: 100px;

        .left-block {
            flex: 2;
            padding-right: 70px;
        }

        .right-block {
            flex: 1;
        }

        .alert-text {
            margin-top: 40px;

            .gray-text {
                margin-top: 10px;
                color: #9b9b9b;
                font-size: 24px;

                &:first-child {
                    margin-top: 0;
                }
            }
            .yellow-block {
                display: inline-block;
                margin-top: 10px;
                padding: 4px 16px;
                background: #f7da3a;
                font-size: 22px;
                border-radius: 3px;
                text-transform: uppercase;
                text-align: center;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .cost-wrap {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 45px;

            .cost {
                font-family: 'Proxima Nova Semibold';
                font-size: 58px;

                span {
                    font-size: 27px;
                }
            }

            .vat {
                padding: 0 0 10px 20px;
                font-family: 'Proxima Nova Light';
                font-size: 17px;

                span {
                    display: block;
                }
            }
        }

        .button-wrap {
            .condition-btn {
                display: none;
            }
        }
    }

    .profitably {
        padding: 5px 20px;
        background: #f7da3a;
        color: #000;
        font-size: 18px;
        text-align: center;
        border-radius: 20px;

        &:first-child {
            margin: 0;
        }

        &.mobile {
            display: none;
        }
    }

    .condition-btn {
        display: inline-block;
        margin-left: 18px;
        padding: 5px 20px;
        font-family: 'Proxima Nova Light';
        font-size: 18px;
        color: #000;
        text-align: center;
        border: 1px solid #000;
        border-radius: 20px;
        text-decoration: none;

        &:first-child {
            margin: 0;
        }
    }
}

/***/

.franchisees-wrap {
    position: relative;
    padding: 100px 0 260px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: #ffffff;
        background: -moz-linear-gradient(top,  #f6f6f6 0%, #ffffff 99%);
        background: -webkit-linear-gradient(top,  #f6f6f6 0%,#ffffff 99%);
        background: linear-gradient(to bottom,  #f6f6f6 0%,#ffffff 99%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff',GradientType=0 );
    }

    .franchisees-title {
        max-width: 1230px;
        margin: 0 auto;
        text-align: center;
    }
}

.franchisees-list {
    margin-top: 120px;

    &-item {
        display: flex;
        margin-top: 100px;
        padding-top: 100px;
        border-top: 1px solid #e6e6e6;

        &:first-child {
            margin-top: 0;
            padding-top: 0;
            border-top: 0;
        }
    }

    .image {
        flex: 1;
        max-width: 350px;
        padding-right: 130px;

        .name {
            flex: 100%;
            margin-top: 40px;
            font-family: 'Geometria Medium';
            font-size: 27px;

            span {
                display: block;
            }
        }

        .town {
            flex: 100%;
            font-family: 'Geometria Medium';
            margin-top: 30px;
            font-size: 21px;
            color: #979797;
        }
    }

    .text {
        flex: 1;

        p {
            margin-top: 30px;
            font-family: 'Geometria';
            font-size: 24px;
            line-height: 35px;

            &:first-child {
                margin-top: 0;
            }
        }

        .button-wrap {
            display: none;
            margin-top: 30px;

            .btn {
                padding: 10px 20px;
                font-family: 'Geometria Medium';
                font-size: 13px;
            }
        }
    }

    .franchisees-statistics {
        flex: 1;
        max-width: 290px;
        padding-left: 100px;

        .item {
            margin-top: 75px;

            &:first-child {
                margin-top: 0;
            }
        }

        .num {
            padding-bottom: 30px;
            font-size: 85px;
        }
        .label {
            font-family: 'Proxima Nova Bold';
            font-size: 20px;
            line-height: 27px;
            text-transform: uppercase;
        }
    }
}

/***/

.faq-wrap {
    padding-top: 100px;

    .spoiler-block {
        margin-top: 130px;
    }
}

/***/




/******************************************************************************/

.tabs {
	&-head {
		&-item {
			cursor: pointer;

			&.active {
				padding: 24px;
				background: #fff;
				color: #000;
				border: 1px solid #000;
				border-radius: 8px;

				&:active {
					margin-bottom: 0;
					border-width: 1px;
				}

				.icon {
					&:before {
						color: #000;
					}
				}

				span {
					color: #000;
				}
			}
		}
	}

	&-body {
		&-item {
			display: none;

			&.active {
				display: block;
			}
		}
	}
}

.form {
	width: 100%;

	.row {
		display: flex;
		margin: 20px 0 0 -20px;

		&:first-child {
			margin-top: 0;
		}

		.col {
			flex: 1;
			padding-left: 20px;
		}
	}

	.btn {
		width: 100%;
		max-width: none;
		outline: none;
	}
}

.input-wrap {
    position: relative;

    .icon {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 36px;
        transform: translateY(-50%);
    }

    .input {
        padding-left: 80px;
    }
}

.input {
	width: 100%;
	height: 90px;
	padding: 0 30px;
    font-family: 'Geometria Bold';
	font-size: 18px;
	color: #000;
    border: 0;
	border-radius: 5px;
	outline: none;

	&::placeholder {
		color: #000;
		text-transform: uppercase;
	}
}


/* Middle
-----------------------------------------------------------------------------*/

.first-block {
    position: relative;
	display: flex;
	width: 100%;
	min-height: 100vh;
    margin-bottom: 140px;
    padding: 330px 0 176px;
	background-image: url("../img/bg.jpg");
	background-size: cover;
	background-position: center;
    color: #fff;
	overflow: hidden;

	.wrap {
		display: flex;
		flex-wrap: wrap;
	}

	.left-block {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 2;
	}

	.title {
		max-width: 1210px;

		p {
			margin-top: 60px;
			font-size: 43px;
		}
	}

    .form {
        margin-top: 90px;
    }

    .mobile-block {
        position: relative;
        display: none;
        margin-top: 230px;
        color: #ffffff;
        text-align: center;
        z-index: 5;

        .btn {
            width: 100%;
            padding: 36px 20px;
            font-size: 25px;
        }

        .phone {
            display: block;
            margin-top: 60px;
            font-family: 'Proxima Nova Bold';
            font-size: 36px;
            color: #ffffff;
            text-decoration: none;
        }
        .free {
            margin-top: 15px;
            font-family: 'Proxima Nova Bold';
            font-size: 20px;
            color: #ffffff;
        }
    }
}


/***/

.form-block-wrap {
	position: relative;
	padding: 160px 40px 120px;

	.left-image {
		position: absolute;
		top: 190px;
		left: 0;
	}
	.right-top-image {
		position: absolute;
		top: 0;
		right: 170px;
	}
	.right-image {
		position: absolute;
		top: 180px;
		right: 0;
	}

	.title {
		position: relative;
		margin: 0 auto;
		z-index: 9;
	}
    p {
        max-width: 1400px;
        margin: 90px auto 0;
    }
}
.form-block {
	display: flex;
	flex-direction: column;
	align-items: center;
    max-width: 1800px;
	margin: 70px auto 0;
	padding: 120px 0 150px;
    background-size: cover;
    border-radius: 10px;

	h2 {
		position: relative;
		z-index: 9;
	}

	.label {
		margin-bottom: 60px;
		color: #7c7c7c;
		font-size: 20px;
	}

    .form {
        max-width: 613px;
    }

    .input {
        height: 110px;
    }

    .btn {
        margin-top: 10px;
        padding: 43px 100px;
    }
}


/*** Modal ***/

.modal {
	position: relative;
	width: calc(100% - 60px);
	max-width: 800px;
    max-height: 95vh;
	padding: 60px 60px 50px;
	background: #fff;
	border-radius: 5px;
    overflow: auto;

	&-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		pointer-events: none;
		transition: 0.2s;
		z-index: 9999;

		&.show {
			opacity: 1;
			pointer-events: all;
		}

		.overflow {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0.8;
			cursor: pointer;
		}

		.title {
			margin-bottom: 30px;
            font-size: 35px;
            line-height: 40px;
		}
	}

	.cross {
		position: absolute;
		top: 50px;
		right: 70px;
		width: 40px;
		height: 40px;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 70%;
			height: 2px;
			margin: 22% 0 0 -10%;
			background: #000;
			transform: rotate(45deg) translate(-50%);
		}

		&:before {
			margin: -26% 0 0 -10%;
			transform: rotate(-45deg) translate(-50%);
		}
	}

	//&-body {
	//	max-height: calc(80vh - 100px);
	//	overflow: auto;
	//}

    .input {
        border: 1px solid;
    }

	p {
		max-width: 700px;
		color: #7c7c7c;
		font-size: 16px;
		line-height: normal;
	}

    .phone-wrap {
        margin-top: 10px;
        text-align: center;

        span {
            display: block;
            margin-bottom: 20px;
            font-family: 'Geometria';
            font-size: 14px;
            text-transform: uppercase;
        }

        a {
            font-family: 'Proxima Nova Bold';
            font-size: 38px;
            text-decoration: none;
            color: #000;
        }
    }
}

@media (max-width: 561px) {
	.modal {
		padding: 40px 30px;
	}
}


@import 'main.scss',
		'_header.scss',
        '_mobile.scss',
		'_footer.scss';