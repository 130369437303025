@font-face {
    font-family: 'als_rublregular';
    src: url('../fonts/rouble/rouble-webfont.woff2') format('woff2'),
    url('../fonts/rouble/rouble-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icons/icomoon.eot?d7r1sb');
	src:  url('../fonts/icons/icomoon.eot?d7r1sb#iefix') format('embedded-opentype'),
	url('../fonts/icons/icomoon.ttf?d7r1sb') format('truetype'),
	url('../fonts/icons/icomoon.woff?d7r1sb') format('woff'),
	url('../fonts/icons/icomoon.svg?d7r1sb#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
	content: "\e900";
}
.icon-calendar:before {
	content: "\e901";
}
.icon-coins:before {
	content: "\e902";
}
.icon-inst:before {
	content: "\e903";
	color: #f00;
}
.icon-lightning:before {
	content: "\e904";
}
.icon-location:before {
	content: "\e905";
}
.icon-mail:before {
	content: "\e906";
	color: #f00;
}
.icon-medal:before {
	content: "\e907";
	color: #ff0505;
}
.icon-phone:before {
	content: "\e908";
	color: #fff;
}
.icon-star:before {
	content: "\e909";
}
.icon-vk:before {
	content: "\e90a";
	color: #f00;
}


@font-face{
	font-family: 'Oswald Light';
	src: url('../fonts/oswald/Oswald-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face{
	font-family: 'Helvetica';
	src: url('../fonts/helvetica_neue/HelveticaNeueCyr-Medium.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face{
	font-family: 'Helvetica Light';
	src: url('../fonts/helvetica_neue/HelveticaNeueCyr-Light.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
}
@font-face{
	font-family: 'Helvetica';
	src: url('../fonts/helvetica_neue/HelveticaNeueCyr-Bold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face{
	font-family: 'Geometria Bold';
	src: url('../fonts/geometria/Geometria-Bold.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face{
	font-family: 'Geometria';
	src: url('../fonts/geometria/Geometria.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face{
	font-family: 'Geometria Medium';
	src: url('../fonts/geometria/Geometria-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}
@font-face{
	font-family: 'Geometria Light';
	src: url('../fonts/geometria/Geometria-Light.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/proxima/ProximaNova-Regular.eot');
	src: local('../fonts/proxima/Proxima Nova Regular'), local('ProximaNova-Light'),
	url('../fonts/proxima/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/proxima/ProximaNova-Regular.woff2') format('woff2'),
	url('../fonts/proxima/ProximaNova-Regular.woff') format('woff'),
	url('../fonts/proxima/ProximaNova-Regular.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Light';
	src: url('../fonts/proxima/ProximaNova-Light.eot');
	src: local('../fonts/proxima/Proxima Nova Light'), local('ProximaNova-Light'),
	url('../fonts/proxima/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/proxima/ProximaNova-Light.woff2') format('woff2'),
	url('../fonts/proxima/ProximaNova-Light.woff') format('woff'),
	url('../fonts/proxima/ProximaNova-Light.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Semibold';
	src: url('../fonts/proxima/ProximaNova-Semibold.eot');
	src: local('../fonts/proxima/Proxima Nova Semibold'), local('ProximaNova-Bold'),
	url('../fonts/proxima/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/proxima/ProximaNova-Semibold.woff2') format('woff2'),
	url('../fonts/proxima/ProximaNova-Semibold.woff') format('woff'),
	url('../fonts/proxima/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Bold';
	src: url('../fonts/proxima/ProximaNova-Bold.eot');
	src: local('../fonts/proxima/Proxima Nova Bold'), local('ProximaNova-Bold'),
	url('../fonts/proxima/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/proxima/ProximaNova-Bold.woff2') format('woff2'),
	url('../fonts/proxima/ProximaNova-Bold.woff') format('woff'),
	url('../fonts/proxima/ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova Black';
	src: url('../fonts/proxima/ProximaNova-Black.eot');
	src: local('../fonts/proxima/Proxima Nova Black'), local('ProximaNova-Black'),
	url('../fonts/proxima/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
	url('../fonts/proxima/ProximaNova-Black.woff2') format('woff2'),
	url('../fonts/proxima/ProximaNova-Black.woff') format('woff'),
	url('../fonts/proxima/ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova Extrabld';
	src: url('../fonts/proxima/ProximaNova-Extrabld.eot');
	src: local('../fonts/proxima/Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
	url('../fonts/proxima/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
	url('../fonts/proxima/ProximaNova-Extrabld.woff2') format('woff2'),
	url('../fonts/proxima/ProximaNova-Extrabld.woff') format('woff'),
	url('../fonts/proxima/ProximaNova-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

$rouble: 'als_rublregular', sans-serif;

.rouble {
    font-family: $rouble;
}